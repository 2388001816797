import qs from 'querystring'
import { Button, Col, Row, Table } from 'antd'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import api from 'services/api'
import { INIT_GLOBAL_RESPONSE } from 'helpers/global-response'
import { Form } from '@unform/web'
import Input from 'components/Input'
import { SearchOutlined } from '@ant-design/icons'
import Select from 'components/Select'

const columns = [
  { key: 'channel', dataIndex: 'channel', title: 'Canal' },
  { key: 'context', dataIndex: 'context', title: 'Contexto' },
  { key: 'message', dataIndex: 'message', title: 'Log' },
  { key: 'created_at', dataIndex: 'created_at', title: 'Data' },
]

const channels = [
  {
    label: 'ERP',
    options: [
      { label: 'MBM', value: 'MBM' },
    ]
  },
  {
    label: 'MARKETPLACE',
    options: [
      { label: 'Mercado Livre', value: 'MERCADO_LIVRE' }
    ]
  }
]

const actions = {
  MBM: {
    label: 'MBM',
    options: [
      { label: '[MBM] Baixar pedido', value: 'SYNC_ORDER_MBM' },
      { label: '[MBM] Atualizar NF pedido', value: 'SYNC_ORDER_NF_MBM' },
      { label: '[MBM] Atualizar seq pedido', value: 'SYNC_ORDER_SEQ_MBM' },
      { label: '[MBM] Atualizar estoque', value: 'STOCK_CHANGE_MBM' },
    ]
  },
  MERCADO_LIVRE: {
    label: 'MERCADO LIVRE',
    options: [
      { label: '[ML] Baixar pedido', value: 'SYNC_ORDER_MERCADO_LIVRE' },
      { label: '[ML] Atualizar NF pedido', value: 'SYNC_ORDER_NF_MERCADO_LIVRE' },
      { label: '[ML] Atualizar estoque', value: 'STOCK_CHANGE_ML' },
    ]
  }
}

const Logs = () => {
  const history = useHistory()
  const location = useLocation()

  const [ logs, setLogs ] = useState<any>(INIT_GLOBAL_RESPONSE)
  const [ actionsByChannel, setActionsByChannel ] = useState<any>(undefined)

  const getLogs = async () => {
    setLogs({ loading: true, result: [] })
    const { data } = await api.get('/logs' + location.search)
    setLogs({ loading: false, ...data })
  }

  useEffect(() => { getLogs() }, [])
  useEffect(() => { getLogs() }, [location.search])


  const handleSubmit = (body: any) => {
    const queryEncode = qs.encode(body)
    history.push(`logs?` + queryEncode)

    return
  }

  return (
    <>
      <h3>Logs</h3>

      <Form onSubmit={handleSubmit}>
        <Row gutter={[10, 10]} align="middle">
          <Col span={11}>
            <Input name="message" label="Log" />
          </Col>
          <Col span={5}>
            <Select
              name="channel"
              label="Canal"
              placeholder="Selecionar Canal"
              onChange={({ value }: any) => setActionsByChannel(actions[value])}
              options={[
                { label: 'Selecionar Canal', value: '' },
                ...channels
              ]}
            />
          </Col>
          <Col span={5}>
          <Select
              name="context"
              label="Contexto"
              placeholder="Selecione o Contexto"
              options={[ actionsByChannel ]}
            />
          </Col>
          <Col span={3}>
            <Button
              icon={<SearchOutlined />}
              type="primary"
              htmlType="submit"
              style={{ marginTop: 20 }}
            >
              Pesquisar
            </Button>
          </Col>
        </Row>
      </Form>

      <Table
        // rowSelection={{
        //   type: 'checkbox',
        //   onChange: (_, selectedRows: any[]) => {
        //     setSelectedRow(selectedRows)
        //     setSelectedOrder(selectedRows.map(s => s.id))
        //   }
        // }}
        size={'small'}
        dataSource={logs.result}
        columns={columns}
        loading={logs.loading}
        // onChange={(pagination, _ , sorter: any) => {
        //   const existFilter = qs.decode(location.search.replace('?', ''))
        //   delete existFilter[sorter.field]

        //   let query: any = { ...existFilter, perpage: pagination.pageSize?.toString(), page: pagination.current?.toString() }
        //   if (sorter.field) {
        //     const ASC_DESC = sorter.order
        //       ? sorter.order === 'ascend' ? 'ASC' : 'DESC'
        //       : undefined

        //     if (ASC_DESC) query.order_by = `${sorter.field},${ASC_DESC}`
        //     history.push(`orders?` +  qs.encode(query))
        //   }

        //   return
        // }}
        pagination={{
          position: ['bottomRight', 'topRight'],
          current: logs.page,
          pageSize: logs.perpage,
          total: logs.total,
          size: 'small',
          showSizeChanger: true,
          onChange: (page, perpage) => {
            const getQuery = qs.decode(location.search.replace('?', ''))
            const query = qs.encode({ ...getQuery, page, perpage })

            history.push('/logs?' + query)
            return
          },
          showTotal: () =>
            `Exibindo ${logs.result.length} de ${logs.total} de registros`
        }}
      />
    </>
  )
}

export default Logs
