import React, { useEffect, useRef } from 'react'
import { useField } from '@unform/core'
import { Label, InputType, Container } from './style'

interface Props extends React.InputHTMLAttributes<any>{
  label?: string
  name: string
  placeHolder?: string
  idInput?: string
  onCh?: any
  value?: any
  type?: string
  className?: string
}

const Input: React.FC<Props> = ({label, name, placeHolder, idInput, onCh, value, type='text', className, ...rest}) => {
  const inputRef = useRef(null)
  const { fieldName, defaultValue, registerField } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField])
  return (
    <Container className={className}>
      {label && <Label>{label}</Label>}
      <InputType
        name={name}
        placeholder={placeHolder}
        id={idInput}
        onChange={onCh}
        value={value}
        type={type}
        ref={inputRef}
        defaultValue={defaultValue}
        { ...rest }
      />
    </Container>
  )
}

export default Input