import { Form } from '@unform/web'
import { Alert, Button, Col, Divider, message, Row, Tabs } from 'antd'
import CheckboxInput from 'components/CheckboxInput'
import Input from 'components/Input'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import api from 'services/api'

const LojaIntegradaEdit = () => {

  const { id }: any = useParams()
  const configRef: any = useRef()
  const history = useHistory()

  const [loadingSendConfig, setLoadingSendConfig] = useState(false)
  const [ integration, setIntegration ] = useState<any>()

  const getIntegration = async () => {
    const { data } = await api.get(`/apps/loja-integrada/${id}`)
    setIntegration(data)

      configRef?.current.setFieldValue('import_order', data.import_order ? ['import_order'] : [])
      configRef?.current.setFieldValue('import_offer', data.import_offer ? ['import_offer'] : [])
      configRef?.current.setFieldValue('import_price', data.import_price ? ['import_price'] : [])
      configRef?.current.setFieldValue('import_stock', data.import_stock ? ['import_stock'] : [])
  }

  useEffect(() => {
    getIntegration()
  }, [])

  const handleSendConfig = async (data: any) => {
    setLoadingSendConfig(true)

    const body = {
      import_order: data.import_order.length > 0,
      import_offer: data.import_offer.length > 0,
      import_price: data.import_price.length > 0,
      import_stock: data.import_stock.length > 0
    }

    const result = await api.put(`/loja-integrada/update/${integration?.id}`, body)
    setLoadingSendConfig(false)

    if (result.data.success) {
      message.success('Configurações Loja Integrada Alteradas com sucesso!')
      history.push('/apps')
      return
    }
  }

  return (
    <>
      <h3>Configurações loja integrada</h3>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Configurações" key="1">
          <Form onSubmit={handleSendConfig} ref={configRef}>
            <h4>Cofiguração de pedido</h4>
            <Row>
              <Col span={24}>
                <CheckboxInput name="import_order" options={[ { label: 'Importa Pedido?', id: 'import_order', value: true } ]} />
              </Col>
            </Row>
            <Divider />
            <h4>Cofiguração do catálogo</h4>
            <Row>
              <Col span={24}>
                <CheckboxInput name="import_offer" options={[ { label: 'Importa Anúncio?', id: 'import_offer', value: true } ]} />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <CheckboxInput name="import_stock" options={[ { label: 'Sicroniza Estoque?', id: 'import_stock', value: true } ]} />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <CheckboxInput name="import_price" options={[ { label: 'Sicroniza Preço?', id: 'import_price', value: true } ]} />
              </Col>
            </Row>
            <Row>
              <Button loading={loadingSendConfig} type="primary" htmlType="submit">
                Salvar
              </Button>
            </Row>
          </Form>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Autenticação" key="3">
        <Form onSubmit={console.log}>
          <Row gutter={10}>
            <Col span={24}>
              <Alert
                message={`Para modificar as informações de acesso entre em contato com o suporte.`}
                type="error"
                closable
                showIcon
              />
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={12}>
              <Input label="Chave API" name="chave_api" disabled value={integration?.chave_api} />
            </Col>
            <Col span={12}>
              <Input label="Aplicação" name="aplicacao" disabled value={integration?.aplicacao} />
            </Col>
          </Row>
        </Form>
        </Tabs.TabPane>
      </Tabs>
    </>
  )
}

export default LojaIntegradaEdit
