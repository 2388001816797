export interface IGlobalResponse {
  perpage: number
  page: number
  lastpage: number
  total: number
  loading?: boolean
  result: any[]
}

export const INIT_GLOBAL_RESPONSE: IGlobalResponse = {
  loading: true,
  perpage: 0,
  page: 0,
  lastpage: 0,
  total: 0,
  result: []
}