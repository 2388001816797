import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`

export const PanelLeft = styled.div`
  width: 40%;
  height: 100vh;
  background: #3A3A60;
  color: white;
  display: flex;
  align-items: center;

  .logo {
    position: absolute;
    top: 0;

    img { width: 180px; margin-top: 20px; margin-left: 20px; }
  }

  .bg {
    text-align: center;
    width: 100%;

    img { width: 70% }
  }
`

export const PanelRight = styled.div`
  width: 60%;
  height: 100vh;
  padding: 0 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  form {
    width: 100%;

    h1 { font-size: 20px; }
    h2 { font-size: 14px; font-weight: normal; }
  }
`