import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 50px;
  background: white;
  display: flex;
  -webkit-box-shadow: 1px 1px 5px 0px rgba(140, 140, 140, 0.75);
  -moz-box-shadow:    1px 1px 5px 0px rgba(140, 140, 140, 0.75);
  box-shadow:         1px 1px 5px 0px rgba(140, 140, 140, 0.75);

  padding: 0 40px;
  align-items: center;
  justify-content: space-between;

  .ant-avatar {
    margin-left: 15px;
  }
`

export const Workspace = styled.div`
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    width: 8px;
    margin-left: 10px;
  }
`
