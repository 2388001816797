import React, { useEffect, useState } from 'react'
import { PDFDocument } from 'pdf-lib'
import { useLocation } from 'react-router-dom'
import api from 'services/api'
import asyncForeach from 'utils/async-foreach'
import { Spin } from 'antd'

const MercadoLivrePrint = () => {
  let { search } = useLocation()

  const [ urls, setUrls ] = useState<string[]>([])
  const [ loading, setLoading ] = useState(true)

  useEffect(() => {
    async function getOrders() {
      const { data } = await api.get(`/mercado-livre/tags${search}`)
      const urls = data.result.map(order => order.shipping_file_url)

      setUrls(urls)
    }

    getOrders()
  }, [])

  useEffect(() => {
    async function load() {
      try {
        const pdfs: ArrayBuffer[] = []

        await asyncForeach(urls, async url => {
          const pdfBytes = await fetch(url).then(res => res.arrayBuffer())
          pdfs.push(pdfBytes)
        })

        const loadPdfs: { [x: string]: PDFDocument }[] = []
        await asyncForeach(pdfs, async (pdf, index) => {
          const load = await PDFDocument.load(pdf)
          loadPdfs.push({ [`pdf_${index}`]: load })
        })

        const pdfDoc = await PDFDocument.create()
        await asyncForeach(loadPdfs, async (pdf, index) => {
          const [ page ] = await pdfDoc.copyPages(pdf[`pdf_${index}`], [0])
          pdfDoc.addPage(page)
        })

        const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
        const iframe: any = window.document.getElementById('pdf')
        iframe.src = pdfDataUri
      } catch (err) {
        console.log(err)
      } finally {
        setLoading(false)
      }
    }

    if (urls.length > 0) load()
  }, [urls])

  return (
    <div style={{ height: '100vh' }}>
      <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <Spin />
        <p>Aguarde enquanto buscamos as etiquetas para você...</p>
      </div>
      <iframe id="pdf" style={{ position: 'absolute', top: 0, width: '100%', height: '100%', border: 0 }} />
    </div>
  )
}

export default MercadoLivrePrint
