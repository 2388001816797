import { Button, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import api from 'services/api'
import removeSpecialChars from 'utils/remove-special-chars'
import { Container, Tag } from './styles'

const _digitVerifyCEP = (cep: any) => {
  let multiplos10 = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
  let valor = 0
  let digito = -1
  cep = cep.split('')

  cep.map((num: string) => {
    valor = valor + parseInt(num)
  })

  multiplos10.map(mult => {
    if (digito === -1 && mult > valor) digito = mult - valor
  })

  return digito
}

const _completeZeroNumber = (num: any) => {
  if (RegExp(/\D/).test(num)) return '000000'

  num = num.split('')
  let count = num.length

  for (let i = 0; i < 6 - count; i++) {
    num.unshift('0')
  }

  return num.join('')
}

const generateDatamatrix = (order: any) => {
  let numero_remetente = _completeZeroNumber(order.correios_service_id.correios.numero_remetente).substr(1, 6)
  const cep_destinatario = removeSpecialChars(order.client.postal_code)
  let string = ''

  const valor_declarado = removeSpecialChars(order.total_gross, 'only_number')

  string = string + cep_destinatario
  string = string + _completeZeroNumber(order.client.number).substr(1, 6)
  string = string + removeSpecialChars(order.correios_service_id.correios.cep_remetente)
  string = string + numero_remetente
  string = string + _digitVerifyCEP(cep_destinatario)
  string = string + '51'
  string = string + order.shipping_tracking.replace(' ', '')
  string = string + '250000000000'
  string = string + order.correios_service_id.correios.postal_card
  string = string + '00000' //_completeZeroNumber(postagem.codigo_servico_postagem).substr(1, 5)
  string = string + '01'
  string = string + numero_remetente
  string = string + '00000000000000000000'
  string = string + _completeZeroNumber(valor_declarado).substr(1, 5)
  string = string + '000000000000'
  string = string + '-00.000000'
  string = string + '-00.000000'

  return string
}


const CorreiosPrintPage = () => {
  let { search } = useLocation()

  const [ tags, setTags ] = useState([])
  const [ loading, setLoading ] = useState(true)

  useEffect(() => {
    async function getOrders() {
      const { data } = await api.get(`/correios/get-order-ticket${search}`)
      setTags(data.result)
    }

    getOrders()

    setTimeout(() => {
      setLoading(false)
      window.print()
    }, 5000)

  }, [])

  return (
    <Container>
      {/* <Button type="primary" className="print-button" onClick={window.print}>
         Imprimir etiquetas
      </Button> */}

      {loading && <Spin />}

      {tags.map((tag: any) => {
        const volumes = tag.shipping_tracking.split(',')
        return volumes.map((vol: string, index: number) => {
          const matrix = generateDatamatrix(tag)

          return (
            <Tag key={tag.id}>
              <div className="header">
                <div className="header_images">
                  <div className="logo">
                    <img src={tag.correios_service_id.correios.photo_url} alt="" />
                  </div>
                  <div className="data_matrix">
                    <img
                      src={`https://bwipjs-api.metafloor.com/?bcid=datamatrix&text=${matrix}`}
                      alt=""
                    />
                  </div>
                  <div className="symbol_service">
                      {
                        tag.correios_service_id.chancela === 'PAC'
                        && <img src={require('../../assets/Images/pac.png')} alt="" />
                      }
                      {
                        tag.correios_service_id.chancela === 'SEDEX'
                        && <img src={require('../../assets/Images/sedex.png')} alt="" />
                      }
                      {
                        tag.correios_service_id.chancela === 'SEDEX_PREMIUM'
                        && <img src={require('../../assets/Images/sedex-premium.png')} alt="" />
                      }
                      {
                        tag.correios_service_id.chancela === 'OUTROS'
                        && <img src={require('../../assets/Images/outros.png')} alt="" />
                      }

                  </div>
                </div>
                <div className="infos">
                  <div>
                    {/* <% if (nacional.numero_nota_fiscal) {
                      <div>NFE: nacional.numero_nota_fiscal</div>
                    <% } */}
                    <div>
                      Ped: {tag.erp_code && tag.erp_code.length > 0 ? tag.erp_code : tag.channel_code}
                    </div>
                  </div>
                  <div>
                    <div>Contrato <b>{tag.correios_service_id.correios.contract}</b></div>
                  </div>
                  <div>
                    <div>Volume: {index + 1}/{tag.volume}</div>
                  </div>
                </div>
              </div>
              <div className="scan">
                <h2>{vol}</h2>
                <img
                  src={`https://bwipjs-api.metafloor.com/?bcid=code128&text=${vol}`}
                  alt=""
                />
              </div>
              <div className="form">
                <div>Recebedor: _______________________________________________________</div>
                <div>
                  <div>Assinatura: __________________________ </div>
                  <div> Documento: ____________________</div>
                </div>
              </div>
              <div className="recipient">
                <div className="recipient_header">
                  <div>
                    <h2>DESTINATÁRIO</h2>
                  </div>
                  <div>
                    <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAAANCAYAAAAZr2hsAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAT1wAAE9cBY3HykQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAUdSURBVEiJzZZ7iF3VFcZ/a587D50kjY3JOefeSaDhqgNTg+JgQqBqsC2trUVoUUeitGlNG0WEJNpW29pYCWJIq6GaUIKPtMUitEEUkUToH1UbaguJpXlIzGvmPOYaEprMzSSTe/bnHzM3Ha9jIoUBP9hw9lrfXnt/a629Ocb5EVTi+HXQlyfYJPEgZrOCotg8UKu9f4EYn2m4C/gLmfUDhyfY6h52m2mFL7mtYRh2TeH5phwXSgBpmh41r5uBOiBnujswfoP4HOLKNuf+CNiUn3SKEHwa0ol6vTZ9RtdBJzso2Q0Yiye4e6bPmHbq5Mnht6bojFOKUvOjWq12jJwafhjoaSWZ7NnBNHuxHEVzzXjgY35YCjzRnM+ePXtaR5u7XbLrME4Ktz1N05cBTZGO/xvnWrcSRS9i3N5KELyeZvk3uqPoazJeoeXaGJzw5hamaboXYF4U9RawDWMOsB/oxKg42eKBLPtnc1kYhhcPDQ3Vp07aeWGMF8MAKnG8ErR+EtZBglKfjY5e4gP3DnBJC0WGfXswy7YCXANteRy9C1yM899KktougLlzLy0PDBxNq9Vqx0h9eK3BDwQzgD2Ie5M8/ytglTh+SKjfjEMjp0fvuKiz/SnEYtBLF3VNf+z0qeFfCZYiZgjeLHndc2Ro6EAlju8ELUuyfElvb2/78WPH1ph0F8ZMsLdx7t4kSd4rl8s9Jr8FuBo4AvZL646iG2RsZ8J1GMcILlgs6T3z/u8YCybJ4/okzVc3p91R9HUZr8n8V9O0tr2VXonDTWDfM2OD9xxyxm2ChTh/LdileHsD4wXhNuP9jWb8VOJJD5tKZmuFvinsaYMaaBkwu+G1oOT4PtjDSZZPq8TRCxj9km006QjwI4yZpbONBWfbSs8ZNl/SU+boc55nSjKem0Q8iFVJkuwsl+d8BbMdwI6PEtyRKM2eSD6SEF0OJufa32wNF4bhHLDlYKsH0+zXANVqdfNIvf5v88GPvWmnwWiS5ssAX4miB4AdaZ7/pFwuXyH5fjPWePGKAOe1C2evBmYrQKMA88JwfgF34bknzbON4/v+vmTsa7S13TfW9Wozs4a54GcD6WBSknjajHUtld1o2JcqUVRP0nwL8LFqAiStBuMDhDUajS8Auye6gkBVZGbSueTs37//TDkO/2FYj4NdAs/YaBahAWBFcRnOkHjEoUfG8j/2fJlxJfAvADl3GQgrFW80QwwNDdUqcbQTdIWwHxp6HrRJRaPRXQ5XujTP12O89D8N/M0K7RHqx3hmXhT1TiZ+MniCbcBwYKyrVqsdE33Ote8FPI6bmrYwDLsMu17wn/PFddKecbGPeqyvZaxq8hqwF5Aawbk/17HO01Wg3VmWHU6yfEnpbKOM8ReJR0uAOkbOLD/T2Xk1aGZDPB44+/P4+q7C+FMYhos+zYudpunR7jheIfT8SH343XIUbXUOJ3Gt9/47Bk9K/LwSRT3m2CdxKzBL5tYa/pZPijtQq71fiaMtEitN6nBOiWTXuVLj/oGBo2klDgHIsuxwdxw/K9OGSjlaiDQIdidwthAbKlG0CsdNBWzD04NZ5gAOHD/+30K6ReK3DvqAzgn7fzFw7ncXEt/EYJb9wWNLDB0yY7nErcKSoiimJ1n+INhqzHq8WA4csMAvStN0H9JpxLFmHJkNy3SiOZ/5+Vl3S6xzxs2S/cIgLIr2+QAGp4DjAGGWrQBbI9EHfBd4J/BalOf5B4x1SJfEaowzMrf0Q2ymQBr0JxOhAAAAAElFTkSuQmCC"
                        alt=""
                      />
                  </div>
                </div>
                <div className="recipient_info">
                  <div>{tag.client.name}</div>
                  <div>{tag.client.street.substr(0, 60)}, {tag.client.number}</div>
                  <div>{tag.client.detail ? tag.client.detail.substr(0, 60) : tag.client.detail}</div>
                  <div>{tag.client.postal_code} {tag.client.city}, {tag.client.neighborhood}/{tag.client.region} </div>
                </div>
                <div className="recipient_bottom">
                  <div>
                    <img
                      src="https://bwipjs-api.metafloor.com/?bcid=code128&text=09271490"
                      alt=""
                    />
                  </div>
                  <div>
                    {/* <!-- OBS: Alguma obs vai aqui --> */}
                  </div>
                </div>
              </div>
              <div className="footer">
                <div><b>Remetente: </b> {tag.correios_service_id.correios.nome_remetente}</div>
                <div>{tag.correios_service_id.correios.logradouro_remetente}, {tag.correios_service_id.correios.numero_remetente} - {tag.correios_service_id.correios.bairro_remetente}</div>
                <div>{tag.correios_service_id.correios.complemento_remetente}</div>
                <div><b>{tag.correios_service_id.correios.cep_remetente}</b> {tag.correios_service_id.correios.cidade_remetente}/{tag.correios_service_id.correios.uf_remetente}</div>
              </div>
            </Tag>
          )
        })
      })}
    </Container>
  )
}

export default CorreiosPrintPage
