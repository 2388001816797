import { Button, message } from 'antd'
import { Form } from '@unform/web'
import Input from 'components/Input'
import React from 'react'
import { Container, PanelLeft, PanelRight } from './styles'
import api from 'services/api'
import { TOKEN_KEY, TOKEN_USER_NAME, TOKEN_WORKSPACE, TOKEN_WORKSPACE_NAME } from 'utils/login'
import { useHistory } from 'react-router-dom'

const LoginPage = () => {
  const history = useHistory()

  const handleSubmit = async (body: any) => {
    try {
      const { data } = await api.post('/login', body)

      await localStorage.setItem(TOKEN_KEY, data.token)
      await localStorage.setItem(TOKEN_WORKSPACE, data.user.company.slug)
      await localStorage.setItem(TOKEN_WORKSPACE_NAME, data.user.company.name)
      await localStorage.setItem(TOKEN_USER_NAME, data.user.first_name)

      history.push('/')
    } catch (err) {
      await message.error('Ops! Ocorreu um erro ao fazer login, verifique as informações.')
    }
  }

  return (
    <Container>
      <PanelLeft>
        <div className="logo">
          <img src="https://www.connectorplaces.com/wp-content/uploads/2020/06/Logo_Fundo-Azul.png" alt=""/>
        </div>
        <div className="bg">
          <img src="http://brandio.io/envato/iofrm/html/images/graphic3.svg" alt=""/>
        </div>
      </PanelLeft>
      <PanelRight>
        <Form onSubmit={handleSubmit}>
          <h1>Acessar sistema</h1>
          <h2>Preencha seus dados cadastrados e realize o login.</h2>
          <Input name="email" label="Seu e-mail" />
          <Input name="password" type="password" label="Sua senha" />

          <Button htmlType="submit" type="primary">Entrar</Button>
        </Form>
      </PanelRight>
    </Container>
  )
}

export default LoginPage