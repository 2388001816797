import { DownOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons'
import { Link } from '@material-ui/core'
import { Avatar, Dropdown, Menu, Tag, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import api from 'services/api'
import { getUserName, getWorkspaceName, TOKEN_KEY, TOKEN_USER_NAME, TOKEN_WORKSPACE, TOKEN_WORKSPACE_NAME } from 'utils/login'
import { Container, Workspace } from './styles'

const { Paragraph } = Typography

const menu = (workspaces: any) => {
  const handleClick = async (workspace: any) => {
    await localStorage.setItem(TOKEN_WORKSPACE, workspace.company.slug)
    await localStorage.setItem(TOKEN_WORKSPACE_NAME, workspace.company.name)

    window.location.reload()
  }

  return (
    <Menu style={{ width: 200 }}>
      {workspaces.map((w: any) => <Menu.Item onClick={() => handleClick(w)}>#{w.company.name}</Menu.Item>)}
    </Menu>
  )
}

const menuAvatar = (
  <Menu style={{ marginLeft: '-150px' }}>
    <Menu.Item
      icon={<LogoutOutlined />}
      onClick={async () => {
        await localStorage.removeItem(TOKEN_KEY)
        await localStorage.removeItem(TOKEN_WORKSPACE)
        await localStorage.removeItem(TOKEN_WORKSPACE_NAME)
        await localStorage.removeItem(TOKEN_USER_NAME)

        window.location.href = '/login'
        return
      }}
    >
      Sair
    </Menu.Item>
  </Menu>
)

const MenuTop = () => {
  const history = useHistory()

  const [ workspaces, setWorkspaces ] = useState([])

  useEffect(() => {
    async function getWorkspaces() {
      const { data } = await api.get('/workspaces')
      const workspace_atual = await localStorage.getItem(TOKEN_WORKSPACE)
      const getList = data.result.map((d: any) => {
        if (d.company.slug !== workspace_atual) return d
      }).filter((f: any) => f !== undefined)

      setWorkspaces(getList)
    }

    getWorkspaces()
  }, [])

  return (
    <Container>
      <span>
        <Dropdown overlay={() => menu(workspaces)}>
          <Workspace>#{getWorkspaceName()} <DownOutlined /></Workspace>
        </Dropdown>
      </span>
      <Tag color="gold">Beta version 1.0</Tag>
      <div>
        <span>Olá, {getUserName()}</span>
        <Dropdown overlay={menuAvatar}>
          <Avatar icon={<UserOutlined />} />
        </Dropdown>
      </div>
    </Container>
  )
}

export default MenuTop
