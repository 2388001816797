import LayoutDefaut from 'containers/LayoutDefault'
import MyApps from 'pages/Apps'
import Credentials from 'pages/Credentials'
import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import MercadoLivreAuthorize from 'pages/Apps/mercado-livre/authorize'
import Login from 'pages/Login'
import Register from 'pages/Register'
import Orders from 'pages/Orders'
import VtexApp from 'pages/Apps/vtex'
import VtexAppEdit from 'pages/Apps/vtex/edit'
import PrivateRoute from './private'
import MercadoLivreApp from 'pages/Apps/mercado-livre'
import MBMApp from 'pages/Apps/mbm'
import MagentoOneApp from 'pages/Apps/magento-one'
import CorreiosApp from 'pages/Apps/correios'
import LinxApp from 'pages/Apps/linx'
import CorreiosPrintPage from 'pages/CorreiosPrint'
import MercadoLivrePrint from 'pages/MercadoLivrePrint'
import MBMAppEdit from 'pages/Apps/mbm/edit'
import Logs from 'pages/Logs'
import Products from 'pages/Products'
import LojaIntegradaApp from 'pages/Apps/loja-integrada'
import LojaIntegradaEdit from 'pages/Apps/loja-integrada/edit'

export default function Routes() {
  return (
    <Router>
      <div>
        <Switch>
          <Route exact={true} path='/mercado-livre/authorize' component={MercadoLivreAuthorize} />
          <Route exact={true} path='/login' component={Login} />
          {/* <Route exact={true} path='/register' component={Register} /> */}
          <Route exact={true} path='/app/:id/credentials' component={Credentials} />
          <PrivateRoute exact={true} path='/correios/print' component={CorreiosPrintPage} />
          <PrivateRoute exact={true} path='/mercado-livre/print' component={MercadoLivrePrint} />
          <LayoutDefaut>
            <PrivateRoute exact={true} path='/' component={MyApps} />
            <PrivateRoute exact={true} path='/orders' component={Orders} />
            <PrivateRoute exact={true} path='/products' component={Products} />
            <PrivateRoute exact={true} path='/logs' component={Logs} />
            {/* App */}
              <PrivateRoute exact={true} path='/apps' component={MyApps} />
              <PrivateRoute exact={true} path='/apps/loja-integrada/create' component={LojaIntegradaApp} />
              <PrivateRoute exact={true} path='/apps/loja-integrada/edit/:id' component={LojaIntegradaEdit} />
              <PrivateRoute exact={true} path='/apps/vtex/create' component={VtexApp} />
              <PrivateRoute exact={true} path='/apps/vtex/edit/:id' component={VtexAppEdit} />
              <PrivateRoute exact={true} path='/apps/mercado-livre/create' component={MercadoLivreApp} />
              <PrivateRoute exact={true} path='/apps/mbm/create' component={MBMApp} />
              <PrivateRoute exact={true} path='/apps/mbm/edit/:id' component={MBMAppEdit} />
              <PrivateRoute exact={true} path='/apps/linx/create' component={LinxApp} />
              <PrivateRoute exact={true} path='/apps/magento-1/create' component={MagentoOneApp} />
              <PrivateRoute exact={true} path='/apps/correios/create' component={CorreiosApp} />
            {/* App */}
          </LayoutDefaut>
        </Switch>
      </div>
    </Router>
  )
}

function Home() {
  return (
    <a
      href='https://auth.mercadolibre.com.ar/authorization?response_type=code&client_id=4009145127051090&redirect_uri=http://localhost:3000/mercado-livre/authorize'
      target='blank'
    >
      Integrar ML
    </a>
  )
}
