import { Form } from '@unform/web'
import { Button, Col, message, Row } from 'antd'
import Input from 'components/Input'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import api from 'services/api'

const MagentoOneApp = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (body: any) => {
    try {
      setLoading(true)
      const { data } = await api.post('/apps/connect/magento-one', body)
      setLoading(false)

      if (data.connect) {
        message.success('App integrado com sucesso!', 1, () => history.push('/apps'))
        return
      }

      message.error(`Ops! não foi possível integrar o App, verifique os dados!`)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      <h1>Integração Magento 1.x</h1>
      <Form onSubmit={handleSubmit}>
        <Row gutter={18}>
          <Col span={10}><Input name="host" label="Host" /></Col>
          <Col span={7}><Input name="user" label="Usuário" /></Col>
          <Col span={7}><Input name="app_key" label="App Key" /></Col>
        </Row>
        <Row>
          <Col>
            <Button loading={loading} type="primary" htmlType="submit">Validar & Salvar</Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default MagentoOneApp
