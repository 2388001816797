import { Menu, message } from 'antd'
import React from 'react'
import { useHistory } from 'react-router-dom'
import api from 'services/api'

type Actions = 'GEN_TICKET' | 'GEN_PLP'

const handleSendMenuAction = async (order_id: number | number[], action: Actions, history: any) => {
  const getAction = {
    GEN_TICKET: {
      action: '/correios/gen-ticket',
      message: 'Aguarde enquanto geramos os rastreios!'
    },
    GEN_PLP: {
      action: '/correios/gen-plp',
      message: 'Aguarde enquanto geramos a PLP'
    }
  }

  order_id = Array.isArray(order_id) ? order_id : [ order_id ]

  const hide =  message.loading(getAction[action].message, 0)
  await api.post(getAction[action].action, { ids: order_id })
  message.success('Ação finalizada com sucesso!', 1)
  window.location.reload()
  hide()
}

type Counter = {
  genTagCorreios: any
  genPLPCorreios: any
  printTagCorreios: any
  downloadTagML: number
  printTagML: any
  downloadOrderERP: number
  syncNfeERP: number
  syncNfeChannel: number
  syncTicketChannel: number
}

const MenuActions = (record: any | any[], counters?: Counter | undefined) => {
  const history = useHistory()

  return (
    <Menu>
      {Array.isArray(record) ? (
        <>
          <Menu.ItemGroup title='Logistica' />
          {record.find((r) => r.correios_service_id !== null) && (
            <Menu.SubMenu title='Correios'>
              <Menu.Item
                disabled={counters?.genTagCorreios.length === 0}
                onClick={() => handleSendMenuAction(counters?.genTagCorreios.map((tag: any) => tag.id), 'GEN_TICKET', history)}
              >
                Gerar rastreio ({ counters?.genTagCorreios.length || 0 })
              </Menu.Item>
              <Menu.Item
                disabled={counters?.genPLPCorreios.length === 0}
                onClick={() => handleSendMenuAction(counters?.genPLPCorreios.map((plp: any) => plp.id), 'GEN_PLP', history)}
              >
                Gerar PLP ({ counters?.genPLPCorreios.length || 0 })
              </Menu.Item>
              <Menu.Item
                disabled={counters?.printTagCorreios.length === 0}
                onClick={() =>
                  window.open(
                    `/correios/print?${counters?.printTagCorreios.map((order: any) => `ids[]=${order.id}`).join('&')}`
                  )
                }
              >
                Imprimir etiqueta ({ counters?.printTagCorreios .length|| 0 })
              </Menu.Item>
            </Menu.SubMenu>
          )}
          {record.find((r) => r.channel === 'MERCADO_LIVRE') && (
            <Menu.SubMenu title='Mercado Envios'>
              {/* <Menu.Item disabled={counters?.downloadTagML === 0}>Baixar etiqueta ({ counters?.downloadTagML || 0 })</Menu.Item> */}
              <Menu.Item
                disabled={counters?.printTagML.length === 0}
                onClick={() =>
                  window.open(
                    `/mercado-livre/print?${counters?.printTagML.map((order: any) => `ids[]=${order.id}`).join('&')}`
                  )
                }
              >
                Imprimir etiqueta ({ counters?.printTagML.length || 0 })
              </Menu.Item>
            </Menu.SubMenu>
          )}
          <Menu.ItemGroup title='ERP' />
          <Menu.SubMenu title='MBM'>
            <Menu.Item disabled={counters?.downloadOrderERP === 0}>Baixar pedido ({ counters?.downloadOrderERP || 0 })</Menu.Item>
            <Menu.Item disabled={counters?.syncNfeERP === 0}>Buscar NF ({ counters?.syncNfeERP || 0 })</Menu.Item>
          </Menu.SubMenu>
          <Menu.ItemGroup title='Canal de venda'>
            <Menu.Item disabled={counters?.syncNfeChannel === 0}>Sicronizar NF ({ counters?.syncNfeChannel || 0 })</Menu.Item>
            <Menu.Item disabled={counters?.syncTicketChannel === 0}>Sicronizar Rastreio ({ counters?.syncTicketChannel || 0 })</Menu.Item>
          </Menu.ItemGroup>
        </>
      ) : (
        <>
          <Menu.ItemGroup title='Logistica' />
          {record.correios_service_id && (
            <Menu.SubMenu title='Correios'>
              <Menu.Item
                disabled={
                  record.shipping_tracking !== null
                }
                onClick={() => handleSendMenuAction(record.id, 'GEN_TICKET', history)}
              >
                Gerar rastreio
              </Menu.Item>
              <Menu.Item
                disabled={record.shipping_plp !== null || record.shipping_tracking === null}
                onClick={() => handleSendMenuAction(record.id, 'GEN_PLP', history)}
              >
                Gerar PLP
              </Menu.Item>
              <Menu.Item
                disabled={record.shipping_tracking === null}
                onClick={() => window.open(`/correios/print?ids[]=${record.id}`)}
              >
                Imprimir etiqueta
              </Menu.Item>
            </Menu.SubMenu>
          )}
          {record.channel === 'MERCADO_LIVRE' && (
            <Menu.SubMenu title='Mercado Envios'>
              {/* <Menu.Item>Baixar etiqueta</Menu.Item> */}
              <Menu.Item
                disabled={record.shipping_file_url === null}
                onClick={() => window.open(`/mercado-livre/print?ids[]=${record.id}`)}
              >
                Imprimir etiqueta
              </Menu.Item>
            </Menu.SubMenu>
          )}
          {/* <Menu.ItemGroup title='ERP' /> */}
          {/* <Menu.SubMenu title='MBM'>
            <Menu.Item disabled={record.erp_code !== null}>Baixar pedido</Menu.Item>
            <Menu.Item
              disabled={record.status.connector_status === 'INVOICED' || record.status.connector_status === 'CANCELED'}
            >
              Buscar NF
            </Menu.Item>
          </Menu.SubMenu> */}
          {/* <Menu.ItemGroup title='Canal de venda'>
            <Menu.Item disabled={record.status.connector_status !== 'INVOICED'}>Sicronizar NF</Menu.Item>
            <Menu.Item disabled={record.shipping_tracking === null}>Sicronizar Rastreio</Menu.Item>
            <Menu.Item disabled={record.status.connector_status === 'CANCELED'} danger>
              Cancelar Pedido
            </Menu.Item>
          </Menu.ItemGroup> */}
        </>
      )}
    </Menu>
  )
}

export default MenuActions
