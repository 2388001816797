import axios from 'axios'

const api = axios.create({
  // baseURL: 'http://localhost:3333',
  baseURL: 'https://beta-api.connectorplaces.com',
  headers: {
    'Content-Type': 'application/json'
  }
})

api.interceptors.request.use(config => {
  const token = localStorage.getItem('@connector:jwt')
  const workspace = localStorage.getItem('@connector:workspace')
  const headers = { ...config.headers }

  if (token) {
    headers.Authorization = `Bearer ${token}`
    headers.workspace = workspace
  }

  return { ...config, headers }
})

export default api