import { Alert, Button, Col, Divider, message, Row, Input as InputAnt, Tabs, Select } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import api from 'services/api'
import Input from 'components/Input'
import { Form } from '@unform/web'
import { FormOutlined } from '@ant-design/icons'
import ConnectorIDE from 'components/ConnectorIDE'
import CheckboxInput from 'components/CheckboxInput'
import MBMDataSync from './edit-data-sync'

const MBMAppEdit = () => {
  const [loadingSendConfig, setLoadingSendConfig] = useState(false)

  const [integration, setIntegration] = useState<any>()
  const [visibleProductIDE, setVisibleProductIDE] = useState(false)
  const [visibleStockIDE, setVisibleStockIDE] = useState(false)
  const [visiblePriceIDE, setVisiblePriceIDE] = useState(false)
  const [visibleNFeIDE, setVisibleNFeIDE] = useState(false)

  const history = useHistory()
  const { id }: any = useParams()

  const configRef: any = useRef()

  useEffect(() => {
    const getIntegration = async () => {
      const { data } = await api.get('/apps/mbm/' + id)
      setIntegration(data)

      configRef?.current.setFieldValue('is_import_invoice', data.is_import_invoice ? ['is_import_invoice'] : [])
      configRef?.current.setFieldValue('import_order', data.import_order ? ['import_order'] : [])
      configRef?.current.setFieldValue('import_price', data.import_price ? ['import_price'] : [])
      configRef?.current.setFieldValue('import_product', data.import_product ? ['import_product'] : [])
      configRef?.current.setFieldValue('import_stock', data.import_stock ? ['import_stock'] : [])
    }

    getIntegration()

    console.log(configRef)
  }, [])

  const handleSendConfig = async (data: any) => {
    setLoadingSendConfig(true)

    const body = {
      is_import_invoice: data.is_import_invoice.length > 0,
      import_order: data.import_order.length > 0,
      import_price: data.import_price.length > 0,
      import_product: data.import_product.length > 0,
      import_stock: data.import_stock.length > 0
    }

    const result = await api.put(`/mbm/update/${integration?.id}`, body)
    setLoadingSendConfig(false)

    if (result.data.success) {
      message.success('Configurações MBM Alteradas com sucesso!')
      history.push('/apps')
      return
    }
  }

  return (
    <>
      <h3>Configurações MBM</h3>

      {visibleProductIDE && (
        <ConnectorIDE
          integration_id={integration?.id}
          title='Configuração de Produto MBM'
          action_exec='/mbm/query-exec'
          action_save={`/mbm/update/${integration?.id}`}
          close={setVisibleProductIDE}
          params={[{ name: 'erp_code', type: 'text', label: 'Cód item ERP (erp_code)', is_required: true }]}
          files={integration?.product_query}
          context="product_query"
          language="sql"
          result_validate={[
            'erp_code',
            'internal_code',
            'base_sku',
            'name',
            'is_active',
            'is_kit'
          ]}
        />
      )}

      {visibleStockIDE && (
        <ConnectorIDE
          integration_id={integration?.id}
          title='Configuração de Estoque MBM'
          action_exec='/mbm/query-exec'
          action_save={`/mbm/update/${integration?.id}`}
          close={setVisibleStockIDE}
          params={[{ name: 'erp_code', type: 'text', label: 'Cód item ERP (erp_code)', is_required: true }]}
          files={integration?.stock_query}
          context="stock_query"
          language="sql"
          result_validate={['internal_code', 'erp_code', 'stock']}
        />
      )}

      {visiblePriceIDE && (
        <ConnectorIDE
          integration_id={integration?.id}
          title='Configuração de Preço MBM'
          action_exec='/mbm/query-exec'
          action_save={`/mbm/update/${integration?.id}`}
          close={setVisiblePriceIDE}
          params={[{ name: 'erp_code', type: 'text', label: 'Cód item ERP (erp_code)', is_required: true }]}
          files={integration?.price_query}
          context="price_query"
          language="sql"
          result_validate={[
            'price_table',
            'sale_price',
            'list_price',
            'promo_start_date',
            'promo_end_date'
          ]}
        />
      )}

      {visibleNFeIDE && (
        <ConnectorIDE
          integration_id={integration?.id}
          title='Configuração de NF-e MBM'
          action_exec='/mbm/query-exec'
          action_save={`/mbm/update/${integration?.id}`}
          close={setVisibleNFeIDE}
          params={[{ name: 'erp_code', type: 'text', label: 'Cód item ERP (erp_code)', is_required: true }]}
          files={integration?.nfe_query}
          context="nfe_query"
          language="sql"
          result_validate={['nfe_key', 'nfe_number', 'nfe_date', 'nfe_xml']}
        />
      )}

      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Configurações" key="1">
          <Form onSubmit={handleSendConfig} ref={configRef}>
            <h4>Cofiguração do catalogo</h4>
            <Row>
              <Col span={24}>
                <CheckboxInput name="import_product" options={[ { label: 'Importa Produto?', id: 'import_product', value: true } ]} />
              </Col>
              <Col span={24}>
                <Button type="link" onClick={() => setVisibleProductIDE(true)}>
                  Query de Produto <FormOutlined style={{ color: '#1890ff', marginLeft: 10 }} />
                </Button>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <CheckboxInput name="import_stock" options={[ { label: 'Importa Estoque?', id: 'import_stock', value: true } ]} />
              </Col>
              <Col span={24}>
                <Button type="link" onClick={() => setVisibleStockIDE(true)}>
                  Query de estoque <FormOutlined style={{ color: '#1890ff', marginLeft: 10 }} />
                </Button>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <CheckboxInput name="import_price" options={[ { label: 'Importa Preço?', id: 'import_price', value: true } ]} />
              </Col>
              <Col span={24}>
                <Button type="link" onClick={() => setVisiblePriceIDE(true)}>
                  Query de Preço <FormOutlined style={{ color: '#1890ff', marginLeft: 10 }} />
                </Button>
              </Col>
            </Row>
            <Divider />
            <h4>Cofiguração de Pedidos</h4>
            <Row>
              <Col span={24}>
                <CheckboxInput name="import_order" options={[ { label: 'Importa Pedido?', id: 'import_order', value: true } ]} />
              </Col>
              <Col span={24}>
                <Button type="link">
                  Configurar regra de baixar pedido <FormOutlined style={{ color: '#1890ff', marginLeft: 10 }} />
                </Button>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <CheckboxInput name="is_import_invoice" options={[ { label: 'Importa NF-e?', id: 'is_import_invoice', value: true } ]} />
              </Col>
              <Col span={24}>
                <Button type="link" onClick={() => setVisibleNFeIDE(true)}>
                  Query de NF-e <FormOutlined style={{ color: '#1890ff', marginLeft: 10 }} />
                </Button>
              </Col>
            </Row>
            <Row>
              <Button loading={loadingSendConfig} type="primary" htmlType="submit">
                Salvar
              </Button>
            </Row>
          </Form>
          {/* <MBMStockConfig integration={integration} /> */}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Data Sync" key="2">
          <MBMDataSync />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Autenticação" key="3">
        <Form onSubmit={console.log}>
          <Row gutter={10}>
            <Col span={24}>
              <Alert
                message={`Para modificar as informações de acesso ao banco entre em contato com o suporte.`}
                type="error"
                closable
                showIcon
              />
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={11}>
              <b>MBM PROD</b>
              <Row gutter={10}>
                <Col span={20}><Input disabled label="Host" name="prod_host" defaultValue={integration?.prod_host} /></Col>
                <Col span={4}><Input disabled label="Porta" name="prod_port" defaultValue={integration?.prod_port} /></Col>
              </Row>
              <Row gutter={10}>
                <Col span={12}><Input disabled label="Usuário" name="prod_user" defaultValue={integration?.prod_user} /></Col>
                <Col span={12}><Input disabled label="Senha" name="prod_password" defaultValue="**********" /></Col>
              </Row>
              <Row gutter={10}>
                <Col span={24}><Input disabled label="Banco de Dados" name="prod_database" defaultValue={integration?.prod_database} /></Col>
              </Row>
            </Col>
            <Col span={2} />
            <Col span={11}>
              <b>MBM ESP</b>
              <Row gutter={10}>
                <Col span={20}><Input disabled label="Host" name="esp_host" defaultValue={integration?.esp_host} /></Col>
                <Col span={4}><Input disabled label="Porta" name="esp_port" defaultValue={integration?.esp_port} /></Col>
              </Row>
              <Row gutter={10}>
                <Col span={12}><Input disabled label="Usuário" name="esp_user" defaultValue={integration?.esp_user} /></Col>
                <Col span={12}><Input disabled label="Senha" name="esp_password" defaultValue="**********" /></Col>
              </Row>
              <Row gutter={10}>
                <Col span={24}><Input disabled label="Banco de Dados" name="esp_database" defaultValue={integration?.esp_database} /></Col>
              </Row>
            </Col>
          </Row>
        </Form>
        </Tabs.TabPane>
      </Tabs>
    </>
  )
}

export default MBMAppEdit
