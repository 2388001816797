import { Container } from './styles'
import React from 'react'

type Props = {
  name: string;
  photo_url: string;
}

const AppIntegration: React.FC<Props> = ({ name, photo_url }) => (
  <Container>
    <img src={photo_url} alt={name}/>
    <p>{name}</p>
  </Container>
)

export default AppIntegration
