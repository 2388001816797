import { Button, Divider, message } from 'antd'
import { Form } from '@unform/web'
import { Col, Row } from 'antd'
import Input from 'components/Input'
import React, { useRef, useState } from 'react'
import api from 'services/api'
import { useHistory } from 'react-router-dom'

const CorreiosApp = () => {
  const history = useHistory()

  const formRef = useRef<any>()
  const [ loadingAuth, setLoadingAuth ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ isValidAuth, setIsValidAuth ] = useState(false)

  const handleSubmit = async (body: any) => {
    try {
      setLoading(true)
      const { data } = await api.post('/apps/connect/correios', body)
      setLoading(false)

      if (data.connect) {
        message.success('App integrado com sucesso!', 1, () => history.push('/apps'))
        return
      }

      message.error(`Ops! não foi possível integrar o App, verifique os dados!`)
    } catch (err) {
      console.log(err)
    }
  }

  const handleAuth = async () => {
    const { current } = formRef
    const body = {
      identity: current.getFieldValue('identity'),
      contract: current.getFieldValue('contract'),
      postal_card: current.getFieldValue('postal_card'),
      user: current.getFieldValue('user'),
      password: current.getFieldValue('password')
    }

    setLoadingAuth(true)
    const { data } = await api.post('/apps/connect/correios-auth', body)
    setLoadingAuth(false)

    if (data.connect) {
      setIsValidAuth(true)
      message.success('Autenticado com sucesso!')
      return
    }

    message.error(`Ops! Falha ao autenticar, verifique as informações!`)
  }

  return (
    <>
      <h1>Integração Correios</h1>
      <Form onSubmit={handleSubmit} ref={formRef}>
          {/* <Col><Input label="photo_url" name="photo_url" /></Col> */}
        <Row gutter={10}>
          <Col span={7}><Input label="Identificador" name="identity" /></Col>
          <Col span={4}><Input label="Cód. Administrativo" name="admin_code" /></Col>
          <Col span={7}><Input label="Contrato" name="contract" /></Col>
          <Col span={6}><Input label="Cartão Postal" name="postal_card" /></Col>
        </Row>
        <Row gutter={10}>
          <Col span={10}><Input label="Usuário" name="user" /></Col>
          <Col span={10}><Input label="Senha" name="password" /></Col>
          <Col span={4}><Input label="Diretoria" name="numero_diretoria" /></Col>
        </Row>
        <Row gutter={10}>
          <Button loading={loadingAuth} onClick={handleAuth}>
            Autenticar
          </Button>
        </Row>
        {isValidAuth && (
          <>
            <Row gutter={10}>
              <Col span={24}>
                <Divider />
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={24}><h2>Endereço Remetente</h2></Col>
              <Col span={8}><Input label="Nome" name="nome_remetente" /></Col>
              <Col span={6}><Input label="CEP" name="cep_remetente" /></Col>
              <Col span={7}><Input label="Logradouro" name="logradouro_remetente" /></Col>
              <Col span={3}><Input label="Número" name="numero_remetente" /></Col>
            </Row>
            <Row gutter={10}>
              <Col span={8}><Input label="Bairro" name="bairro_remetente" /></Col>
              <Col span={8}><Input label="Complemento" name="complemento_remetente" /></Col>
              <Col span={6}><Input label="Cidade" name="cidade_remetente" /></Col>
              <Col span={2}><Input label="UF" name="uf_remetente" /></Col>
            </Row>
            <Row gutter={10}>
              <Col span={6}><Input label="Telefone" name="telefone_remetente" /></Col>
              <Col span={6}><Input label="E-mail" name="email_remetente" /></Col>
            </Row>
            <Row gutter={10}>
              <Col span={4}>
                <Button loading={loading} type="primary" htmlType="submit">
                  Salvar
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Form>
    </>
  )
}

export default CorreiosApp
