import { ConfigProvider } from 'antd'
import { GlobalStyles } from 'global-styles'
import React from 'react'
import ReactDOM from 'react-dom'
import Routes from 'routes/index'
import * as serviceWorker from './serviceWorker'
import ptBR from 'antd/lib/locale/pt_BR'


ReactDOM.render(
  <React.StrictMode>
    <GlobalStyles />
    <ConfigProvider locale={ptBR}>
      <Routes />
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

serviceWorker.unregister()
