import styled from 'styled-components'

export const Container = styled.div`
  height: 100vh;
  width: 60px;
  background-color: #3a3a60;
  text-align: center;
  padding-top: 15px;
  position: fixed;

  .logo {
    width: 38px;
    margin-bottom: 35px;
  }

  ul > li {
    padding: 14px 0;
    list-style: none;
  }

  a {
    color: white;

    svg {
      font-size: 20px;
    }
  }
`