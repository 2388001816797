import styled from 'styled-components'

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  p {
    max-width: 260px;
    text-align: center;
    margin: 15px 0;
  }
`

export const Top = styled.div`
  top: 0;
  position: absolute;
  width: 100%;
  height: 80px;
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo { width: 90px; }

  .close {
    font-size: 40px;
    font-weight: 300;
    color: #3A3A60;
    cursor: pointer;
  }
`