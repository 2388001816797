import { Divider } from '@material-ui/core'
import { Form } from '@unform/web'
import { Button, Col, message, Row } from 'antd'
import Input from 'components/Input'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import api from 'services/api'

const MBMApp = () => {
  const history = useHistory()

  const [ loading, setLoading ] = useState(false)

  const handleSubmit = async (body: any) => {
    setLoading(true)
    const { data } = await api.post('/apps/connect/mbm', body)
    setLoading(false)

    if (data.connect) {
      message.success('App integrado com sucesso!', 1, () => history.push('/apps'))
      return
    }

    message.error('Ops! não foi possível integrar o App, verifique os dados!')
  }

  return (
    <>
      <h1>Integração MBM</h1>
      <Form onSubmit={handleSubmit}>
        <Row gutter={10}>
          <Col span={11}>
            <b>MBM PROD</b>
            <Row gutter={10}>
              <Col span={20}><Input label="Host" name="prod_host" /></Col>
              <Col span={4}><Input label="Porta" name="prod_port" /></Col>
            </Row>
            <Row gutter={10}>
              <Col span={12}><Input label="Usuário" name="prod_user" /></Col>
              <Col span={12}><Input label="Senha" name="prod_password" /></Col>
            </Row>
            <Row gutter={10}>
              <Col span={24}><Input label="Banco de Dados" name="prod_database" /></Col>
            </Row>
          </Col>
          <Col span={2} style={{ display: 'flex', justifyContent: 'center' }}>
            <Divider orientation="vertical" />
          </Col>
          <Col span={11}>
            <b>MBM ESP</b>
            <Row gutter={10}>
              <Col span={20}><Input label="Host" name="esp_host" /></Col>
              <Col span={4}><Input label="Porta" name="esp_port" /></Col>
            </Row>
            <Row gutter={10}>
              <Col span={12}><Input label="Usuário" name="esp_user" /></Col>
              <Col span={12}><Input label="Senha" name="esp_password" /></Col>
            </Row>
            <Row gutter={10}>
              <Col span={24}><Input label="Banco de Dados" name="esp_database" /></Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button loading={loading} type="primary" htmlType="submit">
              Validar & Salvar
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default MBMApp