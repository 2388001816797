import React, { useEffect } from 'react'
import api from 'services/api'

import useQuery from 'helpers/useQuery'
import { Container, Top } from './styles'

import SuccessIcon from 'assets/Images/success.png'

const MercadoLivreAuthorize = () => {
  const query = useQuery()

  useEffect(() => {
    api
      .get(`/mercado-livre/authorize/${query.get('code')}`)
      .then(console.log)
      .catch(console.error)
  })

  return (
    <Container>
      <Top>
        <a href='https://www.connectorplaces.com/' target='blank'>
          <img src='https://www.connectorplaces.com/wp-content/uploads/2020/06/Logo_150.png' className='logo' alt='' />
        </a>
      </Top>
      <div>
        <img src={SuccessIcon} style={{ width: 250 }} alt='Sucesso!' />
      </div>
      <h2>Autorizado com sucesso!</h2>
      <p>Sua conta do Mercado já está vinculada a integração do connectorplaces!</p>
    </Container>
  )
}

export default MercadoLivreAuthorize
