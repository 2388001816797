import history from 'routes/History'

export const TOKEN_KEY = '@connector:jwt'
export const TOKEN_WORKSPACE = '@connector:workspace'
export const TOKEN_WORKSPACE_NAME = '@connector:workspace_name'
export const TOKEN_USER_NAME = '@connector:user_name'

export const login = (token: string) => {
  localStorage.setItem(TOKEN_KEY, token)
}

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY)
  history.push('/login')
}

export const isAuthenticated = () => {
  return !!localStorage.getItem(TOKEN_KEY)
}

export const getWorkspaceName = () =>
  localStorage.getItem(TOKEN_WORKSPACE_NAME) || '-'

export const getUserName = () =>
  localStorage.getItem(TOKEN_USER_NAME) || '-'

export const getToken = (): string | null => localStorage.getItem(TOKEN_KEY)