export default async (array: any, callback: any, continueProcess = true): Promise<any> => {
	if (continueProcess) {
		for (let index = 0; index < array.length; index++) {
			try {
				await callback(array[index], index, array)
			} catch (error) {
				continue
			}
		}
	} else {
		for (let index = 0; index < array.length; index++) {
			try {
				await callback(array[index], index, array)
			} catch (error) {
				throw new Error(error.message)
			}
		}
	}
}
