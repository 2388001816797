import TrackingCorreios from 'tracking-correios'
import { DownOutlined, EllipsisOutlined, InsertRowAboveOutlined, ArrowRightOutlined, EditOutlined, SaveOutlined, DeleteOutlined } from '@ant-design/icons'
import { Tooltip } from '@material-ui/core'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { Button, Col, Divider, Drawer, Dropdown, Menu, message, Modal, Row, Space, Spin, Table, Tabs, Tag } from 'antd'
import CheckboxInput from 'components/CheckboxInput'
import Input from 'components/Input'
import SearchOrder from 'components/SearchOrder'
import Select from 'components/Select'
import { INIT_GLOBAL_RESPONSE } from 'helpers/global-response'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { OptionTypeBase } from 'react-select'
import api from 'services/api'
import useQuery from 'utils/use-query'
import MenuActions from './menu-actions'
import qs from 'querystring'
import Axios from 'axios'

type Column = {
  title: string
  key: string
  dataIndex: string
  render?: any
  ellipsis?: boolean
  width?: number
  sorter?: boolean
}

interface CheckboxOption {
  id: string
  value: string
  label: string
}

const TableFieldsConfig = ({ initialData }: { initialData: string[] }) => {
  const [visible, setVisible] = useState(false)

  const showDrawer = () => setVisible(true)
  const onClose = () => setVisible(false)

  const formRef: any = useRef<FormHandles>(null)
  const checkboxOptions: CheckboxOption[] = [
    { id: 'code', value: 'code', label: 'Código pedido' },
    { id: 'channel', value: 'channel', label: 'Canal de venda' },
    { id: 'client', value: 'client', label: 'Cliente' },
    { id: 'date', value: 'date', label: 'Data do pedido' },
    { id: 'erp', value: 'erp', label: 'Código ERP' },
    { id: 'value', value: 'value', label: 'Valor' },
    { id: 'status', value: 'status', label: 'Status do pedidos' },
    { id: 'nfe_number', value: 'nfe_number', label: 'Número da nota' },
    { id: 'nfe_key', value: 'nfe_key', label: 'Chave da nota' },
    { id: 'ticket', value: 'ticket', label: 'Rastreio' },
    { id: 'plp', value: 'plp', label: 'PLP' },
    { id: 'volume', value: 'volume', label: 'Volume' },
    { id: 'channel_shipping_method', value: 'channel_shipping_method', label: 'Envio' },
    // { id: 'shipping_status', value: 'shipping_status', label: 'Status de envio' },
  ]

  const handleSubmit = async ({ order_table_settings }: any) => {
    let body: any = {}
    order_table_settings.map((k: string) => {
      body[`${k}`] = true
    })
    await api.post('/orders-table-config', body)

    window.location.reload()
  }

  return (
    <div>
      <Tooltip title='Configurar colunas' placement='top'>
        <Button type='primary' shape='circle' onClick={showDrawer} icon={<InsertRowAboveOutlined />} />
      </Tooltip>
      <Form ref={formRef} onSubmit={handleSubmit} initialData={{ order_table_settings: initialData }}>
        <Drawer
          title='Configuração da tabela'
          placement='right'
          width={400}
          closable={false}
          onClose={onClose}
          visible={visible}
          footer={
            <div style={{ textAlign: 'right' }}>
              <Button danger={true} onClick={onClose} style={{ marginRight: 8 }}>
                Cancelar
              </Button>
              <Button onClick={() => formRef.current.submitForm()} type='primary'>
                Salvar
              </Button>
            </div>
          }
        >
          <CheckboxInput name='order_table_settings' options={checkboxOptions} />
        </Drawer>
      </Form>
    </div>
  )
}

const RenderChannel = ({ channel }) => {
  const channels = {
    VTEX: (
      <Tooltip title="VTEX" placement="right-end">
        <img height={20} style={{ borderRadius: 100 }} src={require('../../assets/Icons/vtex.png')} alt=""/>
      </Tooltip>
    ),
    MAGENTO: (
      <Tooltip title="MAGENTO" placement="right-end">
        <img height={20} style={{ borderRadius: 100 }} src={require('../../assets/Icons/magento.png')} alt=""/>
      </Tooltip>
    ),
    MERCADO_LIVRE: (
      <Tooltip title="MERCADO LIVRE" placement="right-end">
        <img height={20} style={{ borderRadius: 100 }} src={require('../../assets/Icons/mercado-livre.png')} alt=""/>
      </Tooltip>
    ),
    B2W: (
      <Tooltip title="B2W" placement="right-end">
        <img height={20} style={{ borderRadius: 100 }} src={require('../../assets/Icons/B2W.png')} alt=""/>
      </Tooltip>
    ),
    TRAY: (
      <Tooltip title="TRAY" placement="right-end">
        <img height={20} style={{ borderRadius: 100 }} src={require('../../assets/Icons/tray.png')} alt=""/>
      </Tooltip>
    ),
    LOJA_INTEGRADA: (
      <Tooltip title="LOJA INTEGRADA" placement="right-end">
        <img height={20} style={{ borderRadius: 100 }} src={require('../../assets/Icons/loja-integrada.png')} alt=""/>
      </Tooltip>
    )
  }

  if (!channels[channel]) return channel

  return channels[channel]
}

type OrderStatus = {
  connector_status: 'null' | 'WAITING_PAYMENT' | 'APPROVED' | 'WAITING_INVOICED' | 'INVOICED' | 'CANCELED',
  channel_status: string
  order_id: string
}

const RenderStatus = ({ status } : { status: OrderStatus }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => setIsModalVisible(true)
  const handleCancel = () => setIsModalVisible(false)

  const options = [
    { value: 'WAITING_PAYMENT', label: 'Pagamento Pendente' },
    { value: 'APPROVED', label: 'Pagamento Aprovado' },
    { value: 'WAITING_INVOICED', label: 'Aguardando NF' },
    { value: 'INVOICED', label: 'Faturado' },
    { value: 'SHIPPED', label: 'Enviado' },
    { value: 'DELIVERED', label: 'Entregue' },
    { value: 'CANCELED', label: 'Cancelado' }
  ]

  const [loading, setLoading] = useState(false)
  const [selectMap, setSelectMap] = useState<OptionTypeBase | null>(null)

  const handleSubmit = async (body: any) => {
    setLoading(true)
    const { data } = await api.post('/orders/status-map', body)
    setLoading(false)

    if (data.error) {
      message.error('Ops! Ocorreu um erro ao mapear o status.')
      return
    }

    message.success('Status mapeado com sucesso!', 1, () => window.location.reload())
  }

  const tags = {
    null: (
      <>
        <Modal
          title="Mapear status [CANAL DE VENDA x CONNECTOR]"
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
        >
          <p>Preencha as informações correspondentes abaixo:</p>
          <Form onSubmit={handleSubmit}>
            <Input name="id" hidden value={status.order_id}/>
            <Input name="description" hidden value={selectMap?.label || ''}/>
            <Input
              name="channel_status"
              value={status.channel_status}
              label="Quando o status no canal de venda for:"
              readOnly
            />

            <Select
              name="connector_status"
              label="O status no Connector deverá ser:"
              options={options}
              onChange={value => setSelectMap(value)}
            />

            <Button loading={loading} style={{ marginTop: 15 }} type="primary" htmlType="submit">
              Salvar
            </Button>
          </Form>
        </Modal>
        <Tooltip title={status.channel_status} placement="right-end">
          <Tag style={{ cursor: 'pointer' }} onClick={showModal}>Status não mapeado</Tag>
        </Tooltip>
      </>
    ),
    WAITING_PAYMENT: (
      <Tooltip title={status.channel_status} placement="right-end">
        <Tag color="#fadb14">Pendente</Tag>
      </Tooltip>
    ),
    APPROVED: (
      <Tooltip title={status.channel_status} placement="right-end">
        <Tag color="#2db7f5">Aprovado</Tag>
      </Tooltip>
    ),
    WAITING_INVOICED: (
      <Tooltip title={status.channel_status} placement="right-end">
        <Tag color="#9ccf86">Aguardando NF-e</Tag>
      </Tooltip>
    ),
    INVOICED: (
      <Tooltip title={status.channel_status} placement="right-end">
        <Tag color="#449b20">Faturado</Tag>
      </Tooltip>
    ),
    SHIPPED: (
      <Tooltip title={status.channel_status} placement="right-end">
        <Tag color="#746DE4">Enviado</Tag>
      </Tooltip>
    ),
    DELIVERED: (
      <Tooltip title={status.channel_status} placement="right-end">
        <Tag color="#3A3A60">Entregue</Tag>
      </Tooltip>
    ),
    CANCELED: (
      <Tooltip title={status.channel_status} placement="right-end">
        <Tag color="#f50">Cancelado</Tag>
      </Tooltip>
    ),
  }

  return tags[status.connector_status]
}

type OrderStatusShipping = 'WAITING_INVOICED' | 'READY_TO_SHIP' | 'SENT' | 'DELIVERED'
const RenderStatusShipping = ({ status }: {status: OrderStatusShipping}) => {
  const tags = {
    WAITING_INVOICED: <Tag color="#fadb14">Aguardando NF-e</Tag>,
    READY_TO_SHIP: <Tag color="#2db7f5">Em Expedição</Tag>,
    SENT: <Tag color="#9ccf86">Em Transito</Tag>,
    DELIVERED: <Tag color="#449b20">Entregue</Tag>,
  }

  return tags[status]
}


const Orders = () => {
  const OrderDetail = ({ visible, record, setOrderDetail }) => {
    const formRef = useRef<any>()

    const [ services, setServices ] = useState<any>({ loading: false, services: [], selected: null, selected_name: null })
    const [disableClient, setDisableClient] = useState(true)


    const handleModifyIntegration = async (e: any) => {
      e.preventDefault()
      const { value } = e.target


      if (value === 'correios') {
        setServices({ loading: true, services: [] })
        const { data } = await api.get('/correios/get-services')
        setServices({ loading: false, services: data.services })
      }
    }

    const hadleSaveModifyIntegration = async () => {
      try {
        const el: any = document.getElementById('service_name')
        const channel_shipping_method = el?.options[el?.selectedIndex].innerHTML
        await api.put(`/orders/${record?.id}`, { correios_service_id: services.selected, channel_shipping_method })

        await message.success(
          'Integração logística modificada com sucesso!',
          1,
          () => window.location.reload()
        )
      } catch (err) {
        message.error('Ops! Ocorreu um erro.')
      }
    }

    const getAddress = async (e) => {
      let { value } = e.target
      value = value.replace('-', '')

      if (value.length !== 8) return

      const { data } = await Axios.get(`https://brasilapi.com.br/api/cep/v1/${value}`)
      formRef?.current?.setFieldValue('client.postal_code', value.replace(/^(\d{5})(\d{3}).*/, '$1-$2'))
      formRef?.current?.setFieldValue('client.street', data.street)
      formRef?.current?.setFieldValue('client.neighborhood', data.neighborhood)
      formRef?.current?.setFieldValue('client.city', data.city)
      formRef?.current?.setFieldValue('client.region', data.state)
    }

    const handleEditClient = async ({ client }) => {
      await api.put(`/orders/client/${client.id}`, client)
      message.success('Endereço alterado com sucesso!', 1)
      window.location.reload()
    }

    return (
      <Drawer onClose={() => setOrderDetail({ visible: false, record: null })} width={700} visible={visible}>

          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="Detalhes do Pedido" key="1">
              <Form onSubmit={handleEditClient} ref={formRef}>
              <div>
                <h3>Informações do cliente</h3>
                <Row gutter={[10, 0]}>
                  <Col md={16}>
                    <Input label="Nome" disabled={true} name="name" defaultValue={record?.client?.name} />
                  </Col>
                  <Col md={8}>
                    <Input label="Documento" disabled={true} name="cpf" defaultValue={record?.client?.document_number}/>
                  </Col>
                </Row>
                <Row gutter={[10, 0]}>
                  <Col md={24}>
                    <Input label="E-mail" disabled={true} name="email" defaultValue={record?.client?.email} />
                  </Col>
                </Row>
              </div>
              <Divider/>
              <div>
                <h3>
                  Informações de entrega
                  {!disableClient &&
                    <Button
                      style={{ float: 'right' }}
                      onClick={() => formRef?.current?.submitForm()}
                      type="primary"
                      shape="circle"
                      size="small"
                      icon={<SaveOutlined />
                    }
                  />}
                  <EditOutlined
                    style={{ color: 'black', float: 'right' }}
                    onClick={() => setDisableClient(false)}
                  />
                </h3>
                <Row gutter={[10, 0]}>
                  <Col md={6}>
                    <Input name="client.id" type="hidden" defaultValue={record?.client?.id} />
                    <Input label="CEP" onChange={getAddress} disabled={disableClient} name="client.postal_code" defaultValue={record?.client?.postal_code} />
                  </Col>
                  <Col md={14}>
                    <Input label="Rua" disabled={disableClient} name="client.street" defaultValue={record?.client?.street} />
                  </Col>
                  <Col md={4}>
                    <Input label="Núm" disabled={disableClient} name="client.number" defaultValue={record?.client?.number}/>
                  </Col>
                </Row>
                <Row gutter={[10, 0]}>
                  <Col md={10}>
                    <Input label="Bairro" disabled={disableClient} name="client.neighborhood" defaultValue={record?.client?.neighborhood} />
                  </Col>
                  <Col md={10}>
                    <Input label="Cidade" disabled={disableClient} name="client.city" defaultValue={record?.client?.city}/>
                  </Col>
                  <Col md={4}>
                    <Input label="Estado" disabled={disableClient} name="client.region" defaultValue={record?.client?.region}/>
                  </Col>
                </Row>
                <Row gutter={[10, 10]}>
                  <Col md={24}>
                    <Input label="Complemento" disabled={disableClient} name="client.detail" defaultValue={record?.client?.detail} />
                  </Col>
                </Row>
              </div>
              </Form>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Integração Logistica" key="2">
              <Form onSubmit={console.log}>
              <h3>Envio</h3>
              <Row gutter={[10, 0]}>
                <Col md={24}>
                  <Input label="Método de envio" disabled={true} name="name" defaultValue={record?.channel_shipping_method} />
                </Col>
              </Row>
              <Divider />

              {record?.correios_service_id ? (
                <>
                  <Row gutter={[10, 0]}>
                    <Col md={12}>
                      <Input label="Serviço" disabled={true} name="name" defaultValue={record?.correios_service_id?.service_description} />
                    </Col>
                    <Col md={12}>
                      <Input label="Cod. Serviço" disabled={true} name="name" defaultValue={record?.correios_service_id?.service_code} />
                    </Col>
                  </Row>
                  {record?.shipping_tracking &&
                    <Row gutter={[10, 0]} align="middle">
                      <Col md={11}>
                        <Input label="Rastreio" disabled={true} name="shipping_tracking" defaultValue={record?.shipping_tracking} />
                      </Col>
                      <Col md={11}>
                        <Input label="PLP" disabled={true} name="shipping_plp" defaultValue={record?.shipping_plp} />
                      </Col>
                      <Col md={2}>
                        <Button
                          type="primary"
                          danger
                          onClick={async () => {
                            await api.put('/orders/' + record?.id, {
                              shipping_tracking: null,
                              shipping_plp: null
                            })

                            message.success(`Rastreio removido com sucesso!`)
                            window.location.reload()
                          }}
                          icon={<DeleteOutlined />}
                        />
                      </Col>
                    </Row>
                  }
                </>
              ) : (
                <Row gutter={[10, 10]}>
                  <h3>Modificar integração logística</h3>
                  <Col span={24}>
                    <select style={{ width: '100%', height: 40 }} onChange={handleModifyIntegration}>
                      <option>Selecione</option>
                      <option value="correios">Correios</option>
                    </select>
                  </Col>
                  {services.services.loading && <Spin />}
                  {services.services.length > 0 && (
                    <>
                      <Col span={24}>
                        <select id="service_name" onChange={({ target }) => setServices({ ...services, selected: target.value })} style={{ width: '100%', height: 40 }}>
                          <option>Selecione</option>
                          {services.services.map((s: any) => <option value={s.id}>{s.service_description}</option>)}
                        </select>
                      </Col>
                      <Col span={24}>
                        <Button type="primary" onClick={hadleSaveModifyIntegration}>Salvar</Button>
                      </Col>
                    </>
                  )}
                </Row>
              )}
              </Form>
            </Tabs.TabPane>
            {/* <Tabs.TabPane tab="Histórico" key="3">
              Content of Tab Pane 3
            </Tabs.TabPane> */}
          </Tabs>
      </Drawer>
    )
  }

  const actions = {
    title: '#',
    key: 'actions',
    dataIndex: 'id',
    width: 40,
    render: (_: any, record: any) => (
      <Dropdown overlay={MenuActions(record)} arrow>
        <Button
          size="small"
          shape="circle"
          icon={<EllipsisOutlined />}
        />
      </Dropdown>
    )
  }

  const [modalVolume, setModalVolume] = useState(false)
  const [modalVolumeInfo, setModalVolumeInfo] = useState<any>()

  const [modalTracking, setModalTracking] = useState(false)
  const [modalTrackingInfo, setModalTrackingInfo] = useState<any>()

  const Allcolumns: Column[] = [
    actions,
    { title: 'Canal', key: 'channel', sorter: true, dataIndex: 'channel', width: 90, render: (channel) => <RenderChannel channel={channel} /> },
    { title: 'Cliente', key: 'client', dataIndex: 'client', ellipsis: true, render: ({ name }: any) => name },
    { title: 'Data', key: 'date', width: 115, ellipsis: true, sorter: true, dataIndex: 'order_date' },
    { title: 'Cod ERP', key: 'erp', sorter: true, dataIndex: 'erp', ellipsis: true },
    { title: 'Código', key: 'code', sorter: true, dataIndex: 'channel_code', ellipsis: true },
    { title: 'Valor', key: 'value', sorter: true, dataIndex: 'total', render: (text: string) => `R$ ${text.replace('.', ',')}` },
    { title: 'Status', key: 'status', sorter: true, dataIndex: 'status', render: (text: OrderStatus) => <RenderStatus status={text} /> },
    { title: 'Envio', key: 'channel_shipping_method', sorter: true, ellipsis: true, dataIndex: 'channel_shipping_method' },
    { title: 'Número da NF', key: 'nfe_number', sorter: true, dataIndex: 'nfe_number' },
    { title: 'Chave da NF', key: 'nfe_key', sorter: true, dataIndex: 'nfe_key' },
    { title: 'Rastreio', key: 'ticket', ellipsis: true, dataIndex: 'ticket', render: (text: string | null, obj: any) => <Button onClick={() => handleClickTracking(obj.shipping_history)} type="link">{text}</Button> },
    { title: 'PLP', key: 'plp', dataIndex: 'plp' },
    { title: 'Vol.', key: 'volume', dataIndex: 'volume', render: (text: string, order: any) => (
      <Tooltip title="Modificar volume">
        <Button type="link" onClick={() => {
          if (order.shipping_tracking !== null) {
            message.info('Não é possível modificar o volume de um pedido com rastreio que já foi gerado!')
            return
          }

          setModalVolumeInfo(order)
          setModalVolume(!modalVolume)
        }}>
          {text}
        </Button>
      </Tooltip>
    )},
    // { title: 'Status Entrega', key: 'shipping_status', dataIndex: 'shipping_status', render: (text: OrderStatusShipping) => <RenderStatusShipping status={text} /> },
  ]

  const location = useLocation()
  const history = useHistory()

  const [orderDetail, setOrderDetail] = useState({ visible: false, record: null })
  const [columns, setColumns] = useState<Column[]>([])

  const [configInit, setConfigInit] = useState<string[]>([])
  const [orders, setOrders] = useState(INIT_GLOBAL_RESPONSE)
  const [ selectedOrder, setSelectedOrder ] = useState<{id: number}[]>([])
  const [ selectedRow, setSelectedRow ] = useState<any[]>([])

  async function getOrders() {
    setOrders(INIT_GLOBAL_RESPONSE)
    const { data } = await api.get('orders' + location.search)
    setOrders({ ...data, loading: false })
  }

  useEffect(() => {
    async function getCollunms() {
      const { data } = await api.get('orders-table-config')

      const fields: string[] = []

      Object.keys(data).map((field) => {
        if (data[field]) fields.push(field)
      })

      setConfigInit(fields)

      const detail = {
        title: '',
        width: 30,
        key: '',
        render: (_, record) =>
          <ArrowRightOutlined
            style={{ cursor: 'pointer', color: 'black' }}
            onClick={() => {
              setOrderDetail({ visible: true, record: null })
              setOrderDetail({ visible: true, record })
            }}
          />
      }

      const config: any = fields.map((c: string) => Allcolumns.find((x) => x.key === c)) || []
      config.length > 0 ? setColumns([ actions, ...config, detail ]) : setColumns(Allcolumns)
    }

    getCollunms()
    getOrders()
  }, [])

  useEffect(() => {
    getOrders()
  }, [location.search])

  const [ genTagCorreios, setGenTagCorreios ] = useState<any[]>()
  const [ genPLPCorreios, setGenPLPCorreios ] = useState<any[]>()
  const [ printTagCorreios, setPrintTagCorreios ] = useState<any[]>()

  const [ downloadTagML, setDownloadTagML ] = useState(0)
  const [ printTagML, setPrintTagML ] = useState<any[]>([])

  const [ downloadOrderERP, setDownloadOrderERP ] = useState(0)
  const [ syncNfeERP, setSyncNfeERP ] = useState(0)

  const [ syncNfeChannel, setSyncNfeChannel ] = useState(0)
  const [ syncTicketChannel, setSyncTicketChannel ] = useState(0)

  useEffect(() => {
    let filterCorreios = selectedRow.filter(c => c.correios_service_id !== null)

    let count_gen_tag = filterCorreios.filter(c => c.shipping_tracking === null)
    let count_plp = filterCorreios.filter(c => c.shipping_tracking !== null && c.shipping_plp === null)
    let count_print_correios = filterCorreios.filter(c => c.shipping_tracking !== null)

    setGenTagCorreios(count_gen_tag)
    setGenPLPCorreios(count_plp)
    setPrintTagCorreios(count_print_correios)

    const filterMercadoLivre = selectedRow.filter(r => r.channel === 'MERCADO_LIVRE')

    let count_dowload_tag_ml = filterMercadoLivre.filter(f => f.channel_status === 'ready_to_ship').length
    let count_print_tag_ml = filterMercadoLivre.filter(f => f.shipping_file_url !== null)

    setDownloadTagML(count_dowload_tag_ml)
    setPrintTagML(count_print_tag_ml)

    let count_download_order_erp = selectedRow.filter(r => r.erp_code === null).length
    let count_sync_nfe_erp = selectedRow.filter(r => r.erp_code !== null && r.status === 'WAITING_INVOICED').length

    setDownloadOrderERP(count_download_order_erp)
    setSyncNfeERP(count_sync_nfe_erp)

    let count_sync_nfe_channel = selectedRow.filter(r => r.nfe_key !== null).length
    let count_sync_ticket_channel = selectedRow.filter(r => r.shipping_tracking !== null).length

    setSyncNfeChannel(count_sync_nfe_channel)
    setSyncTicketChannel(count_sync_ticket_channel)
  }, [selectedRow])

  const handleChangeVolume = async (body: any) => {
    const { data } = await api.put(`/orders/${body.id}`, { volume: body.volume })
    if (data.error) {
      message.error('Ops! Ocorreu um erro ao trocar o volume. Tente novamente mais tarde.')
      return
    }

    message.success('Volume modificado com sucesso!')
    window.location.reload()
  }

  const handleClickTracking = async (info) => {
    setModalTrackingInfo(info)
    setModalTracking(true)
  }

  return (
    <>
      <OrderDetail { ...orderDetail } setOrderDetail={setOrderDetail} />
      <Modal
        width={800}
        style={{ top: 30 }}
        visible={modalTracking}
        title="Acompanhar pedido"
        onCancel={() => setModalTracking(false)}
        footer={null}
      >
        <Table
          columns={[
            { key: 'data', dataIndex: 'data', title: 'Data' },
            { key: 'status', dataIndex: 'status', title: 'Status' },
          ]}
          pagination={false}
          dataSource={modalTrackingInfo}
        />
      </Modal>
      <Modal visible={modalVolume} title="Alterar volume de envio" onCancel={() => setModalVolume(false)} footer={null}>
        <Form onSubmit={handleChangeVolume}>
          <Input name="id" type="hidden" value={modalVolumeInfo?.id} />
          <Input name="volume" type="number" defaultValue={modalVolumeInfo?.volume} min={1} max={5} label="Deseja alterar para qual volume?" />
          <Button type="primary" htmlType="submit">Salvar</Button>
        </Form>
      </Modal>
      <Row>
        <Col span={22}>
          <h1>Pedidos</h1>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <SearchOrder />
        </Col>
      </Row>
      <Row>
        <Space size={15}>
        <TableFieldsConfig initialData={configInit} />
          {selectedRow.length > 0 && (
            <Dropdown
              overlay={
                MenuActions(
                  selectedRow,
                  { genTagCorreios, genPLPCorreios, printTagCorreios, downloadTagML, printTagML, downloadOrderERP, syncNfeERP, syncNfeChannel, syncTicketChannel }
                )
            }>
              <Button>
                Ação em massa <DownOutlined />
              </Button>
            </Dropdown>
          )}
        </Space>
      </Row>
      <div>
        <Row>
          <Col span={24}>
            <Table
              rowSelection={{
                type: 'checkbox',
                onChange: (_, selectedRows: any[]) => {
                  setSelectedRow(selectedRows)
                  setSelectedOrder(selectedRows.map(s => s.id))
                }
              }}
              size={'small'}
              dataSource={orders.result}
              rowClassName={(record) => {
                if (!record.client.postal_code)  return 'tr-error'
                if (!record.client.street)  return 'tr-error'
                if (!record.client.number)  return 'tr-error'
                if (!record.client.city)  return 'tr-error'
                if (!record.client.region)  return 'tr-error'

                return ''
              }}
              columns={columns}
              loading={orders.loading}
              onChange={(pagination, _ , sorter: any) => {
                const existFilter = qs.decode(location.search.replace('?', ''))
                delete existFilter[sorter.field]

                let query: any = { ...existFilter, perpage: pagination.pageSize?.toString(), page: pagination.current?.toString() }
                if (sorter.field) {
                  const ASC_DESC = sorter.order
                    ? sorter.order === 'ascend' ? 'ASC' : 'DESC'
                    : undefined

                  if (ASC_DESC) query.order_by = `${sorter.field},${ASC_DESC}`
                  history.push(`orders?` +  qs.encode(query))
                }

                return
              }}
              pagination={{
                position: ['bottomRight', 'topRight'],
                current: orders.page,
                pageSize: orders.perpage,
                total: orders.total,
                size: 'small',
                showSizeChanger: true,
                onChange: (page, perpage) => {
                  const getQuery = qs.decode(location.search.replace('?', ''))
                  const query = qs.encode({ ...getQuery, page, perpage })

                  history.push('/orders?' + query)
                  return
                },
                showTotal: () =>
                  `Exibindo ${orders.result.length} de ${orders.total} de registros`
              }}
            />
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Orders
