import styled from 'styled-components'

export const Container = styled.div`
  margin: 15px 0;

  input[type=checkbox] {
    height: 20px !important;
    width: 20px !important;
    margin-left: 20px;
  }
`

export const Label = styled.label`
  color: rgb(92, 92, 92);
  font-size: 15px;
  letter-spacing: 1px;
  margin-top: 10px;
`

export const InputType = styled.input`
  width: 100%;
  border-radius: 3px;
  border: 1px solid rgb(192, 192, 192);
  height: 35px;
  padding: 10px;
`