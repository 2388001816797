import styled from 'styled-components'

export const Container = styled.div`
  @media print {
    .print-button { display: none !important }
  }
`

export const Tag = styled.div`
  @page {
    margin: 0;
    padding: 0;
    size: 4in 5.2in;
  }



  @media screen, print {
    width: 106.36mm;
    height: 138.11mm;
    font-size: 10px;
    -webkit-print-color-adjust: exact !important;

    * {
      padding: 0px;
      margin: 0px;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: bold;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
    }

    .header {
      width: 100%;
      height: 35mm;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      /* align-items: center; */
      padding: 2mm 5mm;
    }

    .header .header_images {
      display: flex;
      justify-content: space-between;
    }

    .header .logo {
      width: 25mm;
      height: 25mm;
      display: flex;
      justify-content: center;
      align-items: center;
      /* background: grey; */
    }

    .header .logo img {
      width: 85%;
    }

    .header .data_matrix {
      width: 25mm;
      height: 25mm;
    }

    .header .data_matrix img {
      width: 100%;
      height: 100%;
    }

    .header .symbol_service {
      width: 20mm;
      height: 20mm;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .header .symbol_service img {
      width: 80%;
    }

    .header .infos {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 1mm;
      font-size: 12.5px;
    }

    .header .infos > div {
      width: 33.33%;
    }

    .header .infos > div:nth-child(2) {
      text-align: center;
      padding-left: 18px;
    }

    .header .infos > div:last-child {
      text-align: right;
    }

    .scan {
      width: 100%;
      height: 25mm;
      padding-top: 1.5mm;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .scan > h2 {
      text-align: center;
      font-weight: bold;
      width: 100%;
    }

    /* .scan > div:first-child {
      margin-left: 5mm;
      width: 80mm;
      text-align: center;
    } */

    /* .scan > div:last-child {
      width: 21.36mm;
      margin-top: 4mm;
      height: 19.9mm;
      padding-left: 5mm;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      font-size: 15px;
      font-weight: bold;
    } */

    .scan img {
      height: 18mm;
      width: 80mm;
    }

    .form {
      width: 100%;
      height: 15mm;
      padding-top: 1.5mm;
      padding-left: 5mm;
    }

    .form > div:last-child {
      display: flex;
      margin-top: 3mm;
    }

    .recipient {
      width: 100%;
      height: 46mm;
      border: 1px solid #000;
    }

    .recipient .recipient_header {
      display: flex;
      justify-content: space-between;
    }

    .recipient .recipient_header img {
      padding-top: 2.5mm;
      padding-right: 5mm;
    }

    .recipient .recipient_info {
      font-size: 12px;
      padding-top: 1.5mm;
      padding-left: 5mm;
    }

    .recipient .recipient_bottom {
      display: flex;
      padding-top: 1.5mm;
    }

    .recipient .recipient_bottom img {
      padding-left: 5mm;
      width: 40mm;
      height: 15mm;
    }

    .recipient .recipient_bottom > div {
      width: 50%;
    }


    .recipient h2 {
      background-color: #000;
      color: white;
      display: initial;
      padding: 1px 10px;
    }

    .footer {
      width: 100%;
      height: 17mm;
      border: 1px solid #000;
      border-top: 0;
      font-size: 8.5px;
      padding: 5px;
      padding-left: 5mm;
    }
  }
`