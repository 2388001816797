import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
`

export const Content = styled.div`
  width: 100%;
  margin-left: 60px;
`

export const Page = styled.div`
  width: 100%;
  padding: 20px 40px;

  h1 {
    color: #575e66 !important;
    font-size: 18px;

    small {
      font-size: 12px;
      margin-left: 10px;
    }
  }
`