import Menu from 'components/MenuTop'
import Navbar from 'components/Navbar'
import React from 'react'
import { Container, Content, Page } from './styles'

const LayoutDefaut: React.FC = ({ children }) => {
  return (
    <Container>
      <Navbar />
      <Content>
        <Menu />
        <Page>{children}</Page>
      </Content>
    </Container>
  )
}

export default LayoutDefaut
