export type removeSpecialCharsRule = 'only_number' | 'only_letter'

export default function removeSpecialChars(
  item: string,
  rule?: removeSpecialCharsRule
) {
  if (rule === 'only_number') return item.replace(/[^0-9]/g, '')
  if (rule === 'only_letter')
    return item
      .normalize('NFD')
      .replace(/[\u0300-\u036f]|[^a-zA-Z\s]/g, '')

  return item
    .normalize('NFD')
    .replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '')
}
