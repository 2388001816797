import React from 'react'

const VtexAppEdit = () => {

  return (
    <>
      <h1>Integração VTEX Editar</h1>
    </>
  )
}

export default VtexAppEdit
