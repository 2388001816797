import { DownOutlined } from '@ant-design/icons'
import { Button, Col, Dropdown, Menu, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { AppList, Header } from './styles'

import api from 'services/api'
import { Link } from 'react-router-dom'
import AppIntegration from 'components/AppIntegration'

type App = {
  name: string
  slug: string
}

const MyApps = () => {
  const [apps, setApps] = useState<App[]>([])
  const [integrations, setIntegrations] = useState<any[]>([])

  useEffect(() => {
    async function getApps() {
      const { data } = await api.get('/apps')
      const { data: dataIntegrations } = await api.get('/my-apps')

      setApps(data)
      setIntegrations(dataIntegrations)
    }

    getApps()
  }, [])

  return (
    <>
      <Header>
        <h1>Integrações <small>({integrations.length})</small></h1>
        <Dropdown overlay={
          <Menu>
            {apps.map(app => (
              <Menu.Item key={app.slug}>
                <Link to={`/apps/${app.slug}/create`}>{app.name}</Link>
              </Menu.Item>
            ))}
          </Menu>
        }>
          <Button type="primary">
            Integrar novo App <DownOutlined />
          </Button>
        </Dropdown>
      </Header>
      <AppList>
        <Row gutter={16}>
          {integrations.map(integration => (
            <Col span={4}>
              <Link to={`/apps/${integration.app.slug}/edit/${integration.integration_id}`}>
                <AppIntegration name={integration.app.name} photo_url={integration.app.photo_url} />
              </Link>
            </Col>
          ))}
        </Row>
      </AppList>
    </>
  )
}

export default MyApps