import React, { useRef, useState } from 'react'
//import MonacoEditor from '@uiw/react-monacoeditor'
import { format } from 'sql-formatter'
import { Container, Explorer, Menu, Content, Editor, Result } from './styles'
import { ArrowRightOutlined, CloseOutlined, CodeOutlined, ExclamationCircleOutlined, PlayCircleOutlined } from '@ant-design/icons'
import { Button, Collapse, message, Modal, Spin, Table } from 'antd'
import { Form } from '@unform/web'
import Input from 'components/Input'
import api from 'services/api'

const options = {
  selectOnLineNumbers: true,
  roundedSelection: false,
  readOnly: false,
  cursorStyle: 'line',
  automaticLayout: false,
  theme: 'vs-dark',
  scrollbar: {
    useShadows: false,
    verticalHasArrows: true,
    horizontalHasArrows: true,
    vertical: 'visible',
    horizontal: 'visible',
    verticalScrollbarSize: 17,
    horizontalScrollbarSize: 17,
    arrowSize: 30,
  },
}

const ConnectorIDE = ({
  title,
  close,
  params,
  files = [],
  language,
  action_exec,
  action_save,
  integration_id,
  context,
  result_validate = [] as string[]
}: any) => {
  const ref = useRef<any>()
  const [isValidResult, setIsValidResult] = useState<boolean | undefined>()
  const [loadingExec, setLoadingExec] = useState(false)
  const [selectedFile, setSelectedFile] = useState('default')
  const [code, setCode] = useState<any>([])
  const [tableResult, setTableResult] = useState<any>([])

  const validateResult = ([ result ]: any) => {
    if (!result) return

    const valid = result_validate.map(r => result[r])
    setIsValidResult(!valid.includes(undefined))
  }

  const handleSubmit = async (body) => {
    let code_exec = code[body.key]
    if (!code_exec) return

    code_exec = code_exec
      .replace(/(\r\n|\n|\r|\t)+/gm, ' ')

    params.forEach(param => {
      code_exec = code_exec.replace(`{{${param?.name}}}`, body[param?.name])
    })

    setIsValidResult(undefined)
    setTableResult([])
    setLoadingExec(true)

    const { data } = await api.post(action_exec, { ...body, code_exec })

    validateResult(data.result)
    setLoadingExec(false)
    setTableResult(data.result)
  }

  const handlePublish = async () => {
    console.log(code)
    Modal.confirm({
      title: 'Tem certeza que deseja salvar?',
      icon: <ExclamationCircleOutlined />,
      content: 'Após salvo a próximo execução irá utilizar este código fonte',
      async onOk() {
        const { data } = await api.put(action_save, { [context]: code })
        if (data.success) {
          message.success('Query Alterada com sucesso!')
          close(false)
          return
        }


      },
      onCancel() {
        console.log('Cancel');
      },
    })
  }

  return (
    <Form onSubmit={handleSubmit} ref={ref}>
      <Container>
          <Menu>
            <nav>
              <ul>
                <li><CodeOutlined /></li>
              </ul>
              <Button
                style={{ position: 'absolute', bottom: 15, left: 13 }}
                type="primary"
                shape="circle"
                icon={<CloseOutlined />}
                onClick={() => close(false)}
                danger
              />
            </nav>
          </Menu>
          <Explorer>
            <Collapse defaultActiveKey={['1']} ghost>
              <Collapse.Panel header={`Parametros (${params.length})`} key="1">
                {params.map(p => (
                  <Input
                    className="connector-ide"
                    name={p.name}
                    type={p.type}
                    required={p.is_required}
                    label={p.label}
                  />
                ))}
              </Collapse.Panel>
              <Collapse.Panel header={`Arquivos (${Object.keys(files).length})`} key="2">
                {Object.keys(files).map(name =>
                  <p style={{ cursor: 'pointer' }} onClick={() => setSelectedFile(name)}>
                    {name}.{language}
                  </p>
                )}
              </Collapse.Panel>
              <Collapse.Panel header={`Retorno obrigatório (${result_validate.length})`} key="3">
                {result_validate.map(r => <p>{r}</p>)}
              </Collapse.Panel>
            </Collapse>
          </Explorer>
          <Content>
            <Editor>
              <div className="menu-top">
                <div className="selected-file">{selectedFile}.{language}</div>
                <div>{title}</div>
                <div className="actions">
                  <button>Executar</button>
                </div>
              </div>
              {/*<MonacoEditor
                height="60vh"
                width="100%"
                language={language}
                onChange={(value) => setCode({ ...code, [selectedFile]: value })}
                value={format(files[selectedFile] || '')}
                options={options as any}
                />*/}
              <Input required type="hidden" name="language" value={language} />
              <Input required type="hidden" name="key" value={selectedFile} />
              <Input required type="hidden" name="integration_id" value={integration_id} />
              <Result>
                {isValidResult === true &&
                  <button onClick={handlePublish} type="button" className="sucess">
                    Retorno válido! Deseja salvar o código fonte? <ArrowRightOutlined />
                  </button>
                }
                {isValidResult === false && <div className="error">Retorno inválido! Para prosseguir é obrigatório retornar: {result_validate.join(', ')}</div> }
                {loadingExec && <Spin />}
                {tableResult.length > 0 ? (
                  <Table
                    pagination={false}
                    size="small"
                    className='time-table-row-select'
                    columns={
                      Object.keys(tableResult[0]).map(t => ({
                        title: t,
                        dataIndex: t,
                        key: t,
                      }))
                    }
                    dataSource={
                      tableResult.map((t, key) => ({
                        key,
                        ...t
                      }))
                    }
                  />
                ): <div />}
              </Result>
            </Editor>
          </Content>
      </Container>
    </Form>
  )

}

export default ConnectorIDE
