import { Button, message, Spin } from 'antd'
import useQuery from 'helpers/useQuery'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import api from 'services/api'

const REDIRECT_AUTH = 'https://auth.mercadolibre.com/authorization?response_type=code&client_id=4009145127051090&redirect_uri=https://beta.connectorplaces.com/apps/mercado-livre/create'

const MercadoLivreApp = () => {
  const history = useHistory()
  const query = useQuery()

  const [ loading, setLoading ] = useState(false)

  async function connectMercadoLivre(code: string) {
    setLoading(true)
    const { data } = await api.post('/apps/connect/mercado-livre', { code })
    setLoading(false)

    if (data.connect) {
      message.success('App integrado com sucesso!', 1, () => history.push('/apps'))
    }
  }

  useEffect(() => {
    const code = query.get('code')
    if (code) connectMercadoLivre(code)
  }, [])

  return (
    <>
      <h1>Integração Mercado Livre</h1>

      {query.get('code') ? (
        <Spin />
      ) : (
        <Button loading={loading} type="primary" onClick={() => window.location.href=REDIRECT_AUTH}>
          Permitir acesso a Integração
        </Button>
      )}

    </>
  )
}

export default MercadoLivreApp
