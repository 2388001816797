import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 60px;
  padding-left: 40px;
  padding-right: 40px;

  .app-logo {
    width: 100%;
    text-align: center;
    margin-bottom: 50px;
  }

  button {
    float: right;
  }
`