import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  background: white;
  border: 1px solid #d9d9d9;
  margin: 15px 0;
`

export const TopSearch = styled.div`
  width: 100%;
  display: flex;
  min-height: 40px;
  border-bottom: 1px solid #d9d9d9;

  #search_by {
    display: flex;
    padding: 0 10px;
    width: 100%;
    align-items: center;

    select {
      border: none;
      height: 100%;
      width: 15%;
      padding: 0 10px;
      margin-right: 10px;
    }

    .input-search-order {
      border-left: 1px solid #d9d9d9;
      width: 85%;
      margin: 0 !important;

      input {
        height: 100% !important;
        border: none;
      }
    }

    .anticon svg {
      font-size: 20px;
      cursor: pointer;
    }
  }
`

export const BottomSearch = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 40px;

  .item_search {
    display: flex;
    cursor: pointer;
    padding: 0 15px;
    border-right: 1px solid #d9d9d9;
  }

  .menu-count {
    display: flex;
    justify-content: space-between;

    .ant-tag {
      margin-left: 20px;
    }
  }
`
