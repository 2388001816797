import React from 'react'
import { Link } from 'react-router-dom'
import { AppstoreAddOutlined, HistoryOutlined, ShoppingCartOutlined, TagOutlined, TeamOutlined } from '@ant-design/icons'

import { Container } from './styles'

import Logo from 'assets/Images/logo-white.png'
import { message, Tooltip } from 'antd'

const Navbar = () => {
  return (
    <Container>
      <img className="logo" src={Logo} alt=""/>

      <ul>
        <li>
          <Tooltip title="Pedidos" placement="right">
            <Link to="/orders"><ShoppingCartOutlined /></Link>
          </Tooltip>
        </li>
        <li>
          <Tooltip title="Catálogo" placement="right">
            <Link to="/products"><TagOutlined /></Link>
          </Tooltip>
        </li>
        <li>
          <Tooltip title="Apps" placement="right">
            <Link to="/apps"><AppstoreAddOutlined /></Link>
          </Tooltip>
        </li>
        <li>
          <Tooltip title="Time" placement="right">
            <Link to="#">
              <TeamOutlined onClick={() => message.info('Módulo em desenvolvimento')} />
            </Link>
          </Tooltip>
        </li>
        <li>
          <Tooltip title="Logs" placement="right">
            <Link to="/logs">
              <HistoryOutlined />
            </Link>
          </Tooltip>
        </li>
      </ul>
    </Container>
  )
}

export default Navbar
