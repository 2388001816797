import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 90%;
  padding: 15px;
  margin-top: 15px;
  overflow: scroll;
  color: white;
  background: #25293A;
  border-radius: 5px;
`