import 'antd/dist/antd.css'
import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap');

  :root {
    --primary-color: #3A3A60;
    --primary-text-color: #626072;
    --secondary-color: #FFC27D;
    --dark-secondary-color: #F08000;
    --third-color: #00F6BD;
    --black: #777;
    --white: #FFFFFF;
    --border: rgb(0, 246, 189, 0.2);
    --gray: rgb(92, 92, 92);
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
  }

  ul li {
    list-style: none !important;
  }

  body {
    background-color: #F6F7F8;
    color: #575e66;

    .ant-modal-header {
      background-color: #3a3a60;

      .ant-modal-title { color: #eee; }
    }

    .anticon { color: #eee; }
  }

  button {
    cursor: pointer;
  }

  .ant-table-cell {
    font-size: 14px !important;
  }

  .input-checkbox {
    display: flex;
    flex-direction: row-reverse;
    justify-content: start;
    align-items: center;

    label {
       width: 100%;
       height: 100%;
       margin: 0;
    }

    input {
      height: 15px;
      width: 30px;
      float: left;
    }
  }

  .tr-error, .tr-error:hover {
    background: #fab1a0 !important;
  }
`
