import sleep from 'helpers/sleep'
import React, { useEffect, useState } from 'react'
import { Container } from './styles'

const Logger: React.FC<any> = ({ logs = [], loading = true }) => {

  const [loadingText, setLoadingText] = useState('')

  useEffect(() => {
    const getLoading = async () => {
      await sleep(500)
      loading
        ? setLoadingText(loadingText.concat('.'))
        : setLoadingText('')
    }

    getLoading()
  })

  return (
    <Container>
      <>{ logs.map(log => <div>{log}</div>) }</>
      <>{ loading && <p>{loadingText}</p> }</>
    </Container>
  )
}

export default Logger
