import { Form } from '@unform/web'
import { Button } from 'antd'
import Input from 'components/Input'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import api from 'services/api'

import { Container } from './styles'

const Credentials = () => {
  type Field = {
    _id: string
    key: string
    label: string
    placeholder: string
    required: Boolean
  }

  type App = {
    security_type: string
    _id: string
    name: string
    photo_url: string
    oauth_redirect: string
    fields: Field[]
    createdAt: Date
    updatedAt: Date
    __v: Number
  }

  const [ app, setApp ] = useState<App>()
  const { id } = useParams<{ id: string }>()

  useEffect(() => {
    api.get(`apps/${id}`)
      .then(({ data }: { data: App }) => setApp(data))
  }, [])

  const handleSubmit = async (body: any) => {
    await api.post('integration', body)

    setTimeout(() => window.close(), 1500)
  }

  return (
    <Container>
      <div className="app-logo">
        <img src={app?.photo_url} alt=""/>
      </div>

      {app?.security_type !== 'oauth_2' ? (
        <Form onSubmit={handleSubmit}>
          {app?.fields.map(field => (
            <Input
              type="text"
              label={field.label}
              name={field.key}
            />
          ))}

          <Button type="primary" htmlType="submit">Autorizar</Button>
        </Form>
      ): (
        <a href={app.oauth_redirect}>Autorizar</a>
      )}
    </Container>
  )
}

export default Credentials
