import qs from 'querystring'
import { SearchOutlined } from '@ant-design/icons'
import { Form } from '@unform/web'
import { Button, Checkbox, Col, DatePicker, Dropdown, Menu, Row, Tag } from 'antd'
import Input from 'components/Input'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { BottomSearch, Container, TopSearch } from './styles'
import useQuery from 'utils/use-query'
import api from 'services/api'

const menu = (
  <Menu>
    <Menu.Item>
      <a target="_blank" rel="noopener noreferrer" href="http://www.alipay.com/">
        1st menu item
      </a>
    </Menu.Item>
    <Menu.Item>
      <a target="_blank" rel="noopener noreferrer" href="http://www.taobao.com/">
        2nd menu item
      </a>
    </Menu.Item>
    <Menu.Item>
      <a target="_blank" rel="noopener noreferrer" href="http://www.tmall.com/">
        3rd menu item
      </a>
    </Menu.Item>
  </Menu>
)


const SearchOrder = () => {
  const ref = useRef<any>(null)
  const location = useLocation()
  const history = useHistory()
  const query = useQuery()

  const [ searchBy, setSearchBy ] = useState('channel_code')
  const [ visibleMenuStatus, setVisibleMenuStatus ] = useState(false)
  const [ menuStatusSelected, setMenuStatusSelected ] = useState([])
  const [ menuChannelSelected, setMenuChannelSelected ] = useState([])

  const [ channels, setChannels ] = useState([])

  const handleSubmit = (body: any) => {
    setMenuStatusSelected([])
    setMenuChannelSelected([])
    const queryEncode = qs.encode(body)
    history.push(`orders?` + queryEncode)

    return
  }

  const getChannels = async () => {
    const { data } = await api.get('/my-apps')
    const channels = data.filter(c => c.app.category === 'ECOMMERCE' || c.app.category === 'MARKETPLACE')

    setChannels(channels)
  }

  useEffect(() => {
    getChannels()
  }, [])

  const menuStatus = (location: any, history: any) => {
    const options = [
      { value: 'WAITING_PAYMENT', label: 'Pendente' },
      { value: 'APPROVED', label: 'Aprovado' },
      { value: 'WAITING_INVOICED', label: 'Aguardando NF' },
      { value: 'INVOICED', label: 'Faturado' },
      { value: 'SHIPPED', label: 'Enviado' },
      { value: 'DELIVERED', label: 'Entregue' },
      { value: 'CANCELED', label: 'Cancelado' },
      // { value: null, label: 'Não mapeado' }
    ]

    return (
      <Checkbox.Group style={{ width: '100%' }} onChange={(values) => {
        setMenuStatusSelected(values as any)

        let query: any = {}
        values.map((v, i) => query[`status[${i}]`] = v)
        let existFilter = qs.decode(location.search.replace('?', ''))

        Object.keys(existFilter).map(k => {
          if (k.includes('status')) delete existFilter[k]
        })

        query = { ...existFilter, ...query }
        delete query.page

        let buildQuery = qs.encode(query)
        history.push(`orders?` + buildQuery)
      }}>
        <Menu>
          {options.map(o => (
            <Menu.Item><Checkbox value={o.value}>{o.label}</Checkbox></Menu.Item>
          ))}
        </Menu>
      </Checkbox.Group>
    )
  }

  const menuChannels = (location: any, history: any) => {
    return (
      <Checkbox.Group style={{ width: '100%' }} onChange={(values) => {
        console.log(values)
        setMenuChannelSelected(values as any)

        let query: any = {}
        values.map((v, i) => query[`app[${i}]`] = v)
        let existFilter = qs.decode(location.search.replace('?', ''))

        Object.keys(existFilter).map(k => {
          if (k.includes('app')) delete existFilter[k]
        })

        query = { ...existFilter, ...query }
        delete query.page

        let buildQuery = qs.encode(query)
        history.push(`orders?` + buildQuery)
      }}>
        <Menu>
          {channels.map((o: any) => (
            <Menu.Item><Checkbox value={o.app.id}>{o.app.name}</Checkbox></Menu.Item>
          ))}
        </Menu>
      </Checkbox.Group>
    )
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit} ref={ref}>
        <TopSearch>
          <div id="search_by">
            <select name="search_by" onChange={(e) => setSearchBy(e.target.value)}>
              <option value="channel_code">Nº Pedido Canal</option>
              <option value="erp_code">Nº Pedido ERP</option>
              <option value="nfe_number">Nº NF</option>
              <option value="channel_shipping_method">Envio</option>
              <option value="name">Nome</option>
              <option value="document">CPF/CNPJ</option>
              <option value="cep">CEP</option>
            </select>

            <Input
              className="input-search-order"
              type="text"
              name={searchBy}
              placeHolder="Buscar"
            />
            <Button htmlType="submit">
              <SearchOutlined size={40} />
            </Button>
          </div>
        </TopSearch>
        <BottomSearch>
          {/* <div className="item_search">
            <Dropdown overlay={menu} placement="bottomLeft" arrow>
              <div className="menu-count">Canal <Tag color="#3a3a60">0</Tag></div>
            </Dropdown>
          </div> */}
          <div className="item_search">
            <Dropdown
              overlay={() => menuStatus(location, history)}
              onVisibleChange={flag => setVisibleMenuStatus(flag)}
              visible={visibleMenuStatus}
              placement="bottomLeft"
              arrow
            >
              <div className="menu-count">Status <Tag color="#3a3a60">{menuStatusSelected.length}</Tag></div>
            </Dropdown>
          </div>
          <div className="item_search">
            <Dropdown overlay={() => menuChannels(location, history)} placement="bottomLeft" arrow>
              <div className="menu-count">Canal <Tag color="#3a3a60">{menuChannelSelected.length}</Tag></div>
            </Dropdown>
          </div>
          {/* <div className="item_search">
            <Dropdown overlay={menu} placement="bottomLeft" arrow>
              <div className="menu-count">Transportadora <Tag color="#3a3a60">0</Tag></div>
            </Dropdown>
          </div> */}
          {/* <div>
            <DatePicker.RangePicker bordered={false} />
          </div> */}
        </BottomSearch>
      </Form>
    </Container>
  )
}

export default SearchOrder
