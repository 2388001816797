import qs from 'querystring'
import { Dropdown, Menu, Table, Tooltip } from 'antd'
import { INIT_GLOBAL_RESPONSE } from 'helpers/global-response'
import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import api from 'services/api'
import { MoreOutlined } from '@ant-design/icons'

const getOrigem = (origem) => {
  console.log(origem)

  if (origem === 'VIA_SOFT')
    return (
      <Tooltip title="ViaSoft" placement="right">
        <img
          src={require('../../assets/Icons/via-soft.png')}
          width={20}
          style={{ borderRadius: '100%' }}
        />
      </Tooltip>
    )

  if (origem === 'MBM')
    return (
      <Tooltip title="MBM" placement="right">
        <img
          src={require('../../assets/Images/mbm.png')}
          width={25}
          style={{ borderRadius: '100%' }}
        />
      </Tooltip>
    )

  return '-'
}

const Products = () => {
  const columns = [
    { title: 'Origem', width: 80, ellipsis: true, key: 'origem', dataIndex: 'origem', render: (text) => getOrigem(text) },
    { title: 'Cód ERP', width: 100, ellipsis: true, key: 'erp_code', dataIndex: 'erp_code' },
    { title: 'Cód Interno', width: 100, ellipsis: true, key: 'internal_code', dataIndex: 'internal_code' },
    { title: 'Nome', key: 'name', ellipsis: true, dataIndex: 'name' },
    {
      title: 'Anúncio(s)',
      width: 200,
      render: (_) => {
        let count_offers = 0
        count_offers = count_offers + _.mercado_livre_offer.length

        return `${count_offers} Anúncio(s)`
      }
    },
    {
      title: 'Ações',
      width: 80,
      render: (_, row) =>  (
        <Dropdown overlay={(
          <Menu>
            <Menu.Item>
              <Link to="#">Mapear & Publicar</Link>
            </Menu.Item>
          </Menu>
        )}>
          <MoreOutlined style={{ color: 'black', fontSize: 20, cursor: 'pointer' }} />
        </Dropdown>
      )
    }
  ]

  const history = useHistory()
  const location = useLocation()

  const [ products, setProducts ] = useState<any>(INIT_GLOBAL_RESPONSE)

  const getProducts = async () => {
    setProducts({ loading: true, result: [] })
    const { data } = await api.get('/products' + location.search)
    setProducts({ loading: false, ...data })
  }

  useEffect(() => { getProducts() }, [])
  useEffect(() => { getProducts() }, [location.search])


  // const handleSubmit = (body: any) => {
  //   const queryEncode = qs.encode(body)
  //   history.push(`products?` + queryEncode)

  //   return
  // }

  return (
    <>
      <h3>Meus Produtos</h3>

      <Table
        rowSelection={{
          type: 'checkbox',
          onChange: (_, selectedRows: any[]) => {
            console.log(selectedRows)
          }
        }}
        columns={columns}
        size={'small'}
        dataSource={products.result}
        loading={products.loading}
        pagination={{
          position: ['bottomRight', 'topRight'],
          current: products.page,
          pageSize: products.perpage,
          total: products.total,
          size: 'small',
          showSizeChanger: true,
          onChange: (page, perpage) => {
            const getQuery = qs.decode(location.search.replace('?', ''))
            const query = qs.encode({ ...getQuery, page, perpage })

            history.push('/products?' + query)
            return
          },
          showTotal: () =>
            `Exibindo ${products.result.length} de ${products.total} de registros`
        }}
      />
    </>
  )
}

export default Products
