import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  background: #1E1E1E;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .connector-ide {
    margin: 0 !important;
    padding: 0 !important;
    color: #ccc !important;

    label {
      font-size: 12px;
    }

    input {
      background: #333;
    }

  }
`

export const Menu = styled.div`
  width: 60px;
  height: 100vh;
  background: #333;

  nav, ul {
    width: 100%;
    height: 100vh;
  }

  nav ul li {
    list-style: none;
    text-align: center;
    font-size: 24px;
    padding: 6px 0;
    cursor: pointer;
  }
`

export const Explorer = styled.div`
  width: 250px;
  height: 100vh;
  background: #252526;
  color: #CCCCCC !important;

  .ant-collapse > .ant-collapse-item > .ant-collapse-header,
  .ant-collapse-content {
    color: #CCCCCC !important;
  }
`

export const Content = styled.div`
  width: calc(100% - 250px - 60px);
  height: 100vh;
`

export const Editor = styled.div`
  .menu-top {
    width: 100%;
    height: 40px;
    background: #333;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .selected-file {
      display: flex;
      align-items: center;
      margin-left: 5px;
      background: #252526;
      height: 100%;
      padding: 0 15px;
    }

    .actions {
      button {
        background: #4a7548;
        border: none;
        padding: 10px 15px;
        color: #CCC;
      }
    }
  }

  width: 100%;
  height: 100vh;
`

export const Result = styled.div`
  height: 40vh;
  width: 100%;
  overflow: scroll;
  border-top: 1px solid #333333;

  .ant-table-thead > tr > th {
    color: #eee !important;
    background: #333333 !important;
  }

  .time-table-row-select > .ant-spin-nested-loading > .ant-spin-container > div > .ant-table-content > .ant-table-body > table > tbody > tr:hover > td {
    background-color: unset !important;
  }

  .time-table-row-select > .ant-spin-nested-loading > .ant-spin-container > div > .ant-table-content > .ant-table-scroll > .ant-table-body > table > tbody > tr:hover > td {
    background-color: unset !important;
  }

  .ant-table {
    background: #6f6f6f !important;
    color: #fff !important;

    tr:hover, td:hover {
      background: unset !important;
    }
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #333;
  }

  .sucess {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 30px;
    background: #4B7548;
    padding: 0px 15px;
    color: #CCC;
    border: none;
  }

  .error {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 30px;
    background: #c0392b;
    padding: 0px 15px;
    color: #CCC;
    border: none;
  }
`
