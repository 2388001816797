import { Form } from '@unform/web'
import { Col, Row, Button, message } from 'antd'
import Input from 'components/Input'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import api from 'services/api'

const LojaIntegradaApp = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (body: any) => {
    try {
      setLoading(true)
      const { data } = await api.post('/apps/connect/loja-integrada', body)
      setLoading(false)

      if (data.connect) {
        message.success('App integrado com sucesso!', 1, () => history.push('/apps'))
        return
      }

      message.error(`Ops! não foi possível integrar o App, verifique os dados!`)
    } catch (err) {
      setLoading(false)
      message.error(`Ops! não foi possível integrar o App, verifique os dados!`)
      console.log(err)
    }
  }

  return (
    <>
      <h1>Integração Loja Integrada</h1>

      <Form onSubmit={handleSubmit}>
        <Row gutter={18}>
          <Col span={12}><Input name="chave_api" label="Chave da API" /></Col>
          <Col span={12}><Input name="aplicacao" label="Aplicação" /></Col>
        </Row>
        <Row>
          <Col>
            <Button loading={loading} type="primary" htmlType="submit">Validar & Salvar</Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default LojaIntegradaApp
