import React, { useEffect, useRef, InputHTMLAttributes } from 'react'
import { useField } from '@unform/core'

//interface Props extends InputHTMLAttributes<any> {
//  name: string
//  options: {
//    id: string
//    value: any
//    label?: string
//  }[]
//}

const CheckboxInput: React.FC<any> = ({ name, options, ...rest }) => {
  const inputRefs = useRef<any[]>([])
  const { fieldName, registerField, defaultValue = [] } = useField(name)
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs: any[]) => {
        return refs.filter(ref => ref.checked).map(ref => ref.value)
      },
      clearValue: (refs: any[]) => {
        refs.forEach(ref => {
          ref.checked = false
        })
      },
      setValue: (refs: any[], values: string[]) => {
        refs.forEach(ref => {
          if (values.includes(ref.id)) {
            ref.checked = true
          }
        })
      },
    })
  }, [defaultValue, fieldName, registerField])
  return (
    <div>
      {options.map((option, index) => (
        <div>
          <label htmlFor={option.id} key={option.id}>
          <input
            style={{ marginRight: 8 }}
            ref={ref => {
              inputRefs.current[index] = ref as any
            }}
            value={option.value}
            type="checkbox"
            id={option.id}
            {...rest}
          />
          {option.label}
        </label>
      </div>
      ))}
    </div>
  )
}

export default CheckboxInput
