import styled from 'styled-components'

export const Container = styled.div`
  text-align: center;
  padding: 36px 20px 5px;
  margin-bottom: 30px;
  transition: .2s ease-out;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #ddd;
  -webkit-transition: .2s ease-out;
  -webkit-transition: .05s ease-in-out;
  transition: .05s ease-in-out;
  cursor: pointer;

  img {
    width: 60px;
  }

  :hover {
    z-index: 1;
    -webkit-box-shadow: 2px 2px 8px 0 rgba(0,0,0,.1), -2px -2px 8px 0 rgba(0,0,0,.1);
    box-shadow: 2px 2px 8px 0 rgba(0,0,0,.1), -2px -2px 8px 0 rgba(0,0,0,.1);
    -webkit-font-smoothing: subpixel-antialiased;
    border: 1px solid #ddd;
  }

  p {
    margin-top: 10px;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    position: relative;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`