import styled from 'styled-components'

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`

export const Center = styled.div`
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const AppList = styled.div``

export const Categories = styled.div`
  padding: 0 10px;

  b { margin-bottom: 15px }

  ul li { font-size: 14px }
`
