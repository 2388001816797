import React, { useEffect, useState } from 'react'
import { Button, Divider, Drawer, Input as InputAnt, Select, Table, Tag } from 'antd'
import { BranchesOutlined, PlayCircleOutlined } from '@ant-design/icons'
import api from 'services/api'
import { INIT_GLOBAL_RESPONSE } from 'helpers/global-response'
import Logger from 'components/Logger'
import sleep from 'helpers/sleep'

const columns = [
  { title: '#', dataIndex: 'id' },
  { title: 'Job', dataIndex: 'process' },
  { title: 'Início', dataIndex: 'start_date' },
  { title: 'Fim', dataIndex: 'end_date' },
  { title: 'Status', render: ({ status }) => status === 'PROCESS' ? <Tag color="yellow">{status}</Tag> : <Tag color="green">{status}</Tag> }
]

const MBMDataSync = () => {
  const [visibleLog, setVisibleLog] = useState(false)

  const [actionLog, setActionLog] = useState('')
  const [logs, setLogs] = useState(INIT_GLOBAL_RESPONSE)

  const [logsInfo, setLogsInfo] = useState(INIT_GLOBAL_RESPONSE)

  useEffect(() => {
    const getDataSync = async () => {
      setLogs({ ...logs, loading: true })
      const { data } = await api.get('/data-sync')
      setLogs({ ...logs, loading: false, result: data.result })
    }

    getDataSync()
  }, [])

  useEffect(() => {
    const getLog = async () => {
      await sleep(5000)
      const { data } = await api.get(`/logs?perpage=100&page=1&data_sync=${logsInfo.result[0].data_sync.id}`)
      setLogsInfo({ ...logsInfo, loading: false, result: data.result })
    }

    if (visibleLog && logsInfo.result.length > 0) {
      console.log('[GET]')
      getLog()
    }
  })

  const runningAction = async () => {
    const { data } = await api.post(`/mbm/data-sync/product`)
    console.log(data)
    setLogs({ ...logs, result: [ ...logs.result, data ] })
  }

  return (
    <>
      <Drawer
        title="Log data sync"
        visible={visibleLog}
        onClose={() => setVisibleLog(false)}
        width={800}
        placement="right"
      >
        <Logger
          logs={logsInfo.result.map(r => `(${r.created_at}): ${r.message}`)}
          loading={logsInfo.loading}
        />
      </Drawer>
     <InputAnt.Group compact>
        <Button  style={{ width: '10%' }} icon={<BranchesOutlined style={{ color: '#000' }} />} disabled />
        <Select style={{ width: '80%' }} defaultValue="" onChange={value => setActionLog(value)}>
          <Select.Option value="">Selecione uma ação</Select.Option>
          <Select.Option value="import_product">Importar produtos MBM x Connector</Select.Option>
          <Select.Option value="import_price">Importar preço MBM x Connector</Select.Option>
          <Select.Option value="import_stock">Importar estoque MBM x Connector</Select.Option>
        </Select>
        <Button
          onClick={runningAction}
          style={{ width: '10%', background: '#4a7548' }}
          icon={<PlayCircleOutlined style={{ color: '#FFF' }} />}
        />
      </InputAnt.Group>

      <Divider />

      <Table
        columns={columns}
        size="small"
        dataSource={logs.result}
        loading={logs.loading}
        onRow={(record, rowIndex) => {
          return {
            onClick: async event => {
              setVisibleLog(true)

              setLogsInfo({ ...logsInfo, loading: true })
              const { data } = await api.get(`/logs?perpage=100&page=1&data_sync=${record.id}`)
              setLogsInfo({
                ...logsInfo,
                loading: false,
                result: data.result
              })
            }
          }
        }}
      />
    </>
  )
}

export default MBMDataSync
